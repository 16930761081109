import {
  DefaultError,
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from "@tanstack/react-query";
import klydoApiClient from "../../../api/klydo";

const useDeleteKlydoMutation = (
  options: UseMutationOptions<void, DefaultError, string> = {},
) => {
  const queryClient = useQueryClient();
  return useMutation<void, DefaultError, string>({
    mutationFn: (klydoId: string) => klydoApiClient.deleteKlydo(klydoId),
    onSuccess: (data: void, klydoId: string, context: unknown) => {
      queryClient.refetchQueries({
        queryKey: ["klydo", klydoId],
      });
      options?.onSuccess?.(data, klydoId, context);
    },
    ...options,
  });
};

export default useDeleteKlydoMutation;
