import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import adminApiClient from "../../../../api/admin";

const useCreateClockMutation = (
  options: UseMutationOptions<void, Error, string, unknown> = {},
) => {
  return useMutation({
    mutationKey: ["createClock"],
    mutationFn: async (serial: string) => {
      return adminApiClient.createClockAuth(serial);
    },
    ...options,
  });
};
export default useCreateClockMutation;
