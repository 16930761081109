import React, { useEffect, ReactNode } from "react";
import { useInView as useInViewHook } from "react-intersection-observer";

interface InViewProps {
  children: ReactNode;
  onEnterView?: () => void;
  style?: React.CSSProperties;
  rootMargin?: string;
  triggerOnce?: boolean;
}

const InView: React.FC<InViewProps> = ({
  children,
  onEnterView,
  style,
  rootMargin = "50px 0px",
  triggerOnce = true,
}) => {
  const { ref, inView } = useInViewHook({
    triggerOnce,
    rootMargin,
  });

  useEffect(() => {
    if (inView && onEnterView) {
      onEnterView();
    }
  }, [inView, onEnterView]);

  return (
    <div ref={ref} style={style}>
      {inView && children}
    </div>
  );
};

export default InView;
