import useBaseTable from "../hooks/useBaseTable";
import DSCheckbox from "../../../DSCheckBox";
import {
  DSTable,
  DSTableBody,
  DSTableHead,
  DSTableRow,
  DSTableCell,
  DSTablePagination,
  DSTableContainer,
} from "../..";

export interface Column<T> {
  field: keyof T;
  label: string;
  size?: number;
}

export interface DSBaseTableProps<T> {
  data: T[];
  columns: Column<T>[];
  rowBuilder?: (row: T, columns: Column<T>[]) => JSX.Element;
  onPressRow?: (row: T) => void;
  selectedRows?: T[];
  onSelectRows?: (rows: T[]) => void;
  fetchMore?: () => void;
}

const DSBaseTable = <T,>({
  data,
  columns,
  rowBuilder,
  onPressRow,
  selectedRows,
  onSelectRows,
  fetchMore,
}: DSBaseTableProps<T>) => {
  const {
    defaultWidth,
    paginatedData,
    page,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
  } = useBaseTable<T>({
    data,
    columns,
    onPressRow,
    fetchMore,
  });

  return (
    <>
      <DSTableContainer>
        <DSTable stickyHeader>
          <DSTableHead>
            <DSTableRow>
              {onSelectRows && (
                <DSTableCell
                  key={"selectAll"}
                  padding="checkbox"
                  sx={{ width: 50 }}
                >
                  <DSCheckbox
                    checked={selectedRows?.length === data.length}
                    onChange={() => {
                      onSelectRows(data);
                    }}
                  />
                </DSTableCell>
              )}
              {columns.map((col) => (
                <DSTableCell
                  key={col.field as string}
                  sx={{
                    width: col.size || `${defaultWidth}%`,
                    whiteSpace: "nowrap",
                  }}
                >
                  {col.label}
                </DSTableCell>
              ))}
            </DSTableRow>
          </DSTableHead>
          <DSTableBody>
            {paginatedData.map((row, index) => (
              <DSTableRow key={index} hover onClick={() => onPressRow?.(row)}>
                {onSelectRows && (
                  <DSTableCell padding="checkbox" sx={{ width: 50 }}>
                    <DSCheckbox
                      checked={selectedRows?.includes(row)}
                      onChange={() => onSelectRows([row])}
                    />
                  </DSTableCell>
                )}
                {rowBuilder
                  ? rowBuilder(row, columns)
                  : columns.map((col) => (
                      <DSTableCell
                        key={col.field as string}
                        sx={{
                          width: col.size || `${defaultWidth}%`,
                          whiteSpace: "nowrap",
                        }}
                      >
                        {String(row[col.field])}
                      </DSTableCell>
                    ))}
              </DSTableRow>
            ))}
          </DSTableBody>
        </DSTable>
      </DSTableContainer>
      <DSTablePagination
        count={data.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
};

export default DSBaseTable;
