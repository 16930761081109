import React, { forwardRef } from "react";
import { List } from "@mui/material";

const DSList = forwardRef<HTMLUListElement, React.ComponentProps<typeof List>>(
  (props, ref) => {
    return <List ref={ref} {...props} />;
  },
);

export default DSList;
