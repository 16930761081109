import { useMutation, useQueryClient } from "@tanstack/react-query";
import packApiClient from "../../../api/pack";

const usePublishPackMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ["approve-pack"],
    mutationFn: ({ packId }: { packId: string }) => {
      return packApiClient.publishPack(packId);
    },

    onSettled: () =>
      queryClient.refetchQueries({
        queryKey: ["packs"],
      }),
  });
};

export default usePublishPackMutation;
