import { useQuery } from "@tanstack/react-query";
import klydoApiClient from "../../api/klydo";
import { Klydo_deprecated } from "../../Types";

const useKlydo = (klydoId: string) => {
  return useQuery<Klydo_deprecated>({
    queryKey: ["klydo", klydoId],
    queryFn: () => klydoApiClient.getKlydoById(klydoId),
  });
};

export default useKlydo;
