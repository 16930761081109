import { useQuery } from "@tanstack/react-query";
import adminApiClient, {
  MonthlyRegisteredDevicesResponse,
} from "../../api/admin";

export const useMonthlyRegisteredDevices = () => {
  return useQuery<MonthlyRegisteredDevicesResponse[]>({
    queryKey: ["registeredPerFWPerMonth"],
    queryFn: async () => {
      const monthLimit = 3;
      return adminApiClient.getMonthlyRegisteredDevices(monthLimit);
    },
  });
};
