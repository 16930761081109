import DSDialog from "./system-design/DSDialog";
import DSDialogContent from "./system-design/DSDialogContent";
import DSDialogActions from "./system-design/DSDialogActions";
import DSPrimaryButton from "./system-design/DSPrimaryButtom";
import DSSecondaryButton from "./system-design/DSSecondaryButton";
import DSDialogTitle from "./system-design/DSDialogTitle";

export type ConfirmationModalProps = {
  open: boolean;
  message: string;
  onClose: () => void;
  onConfirm: () => void;
};
const ConfirmationModal = ({
  open,
  message,
  onClose,
  onConfirm,
}: ConfirmationModalProps) => {
  return (
    <DSDialog className="modal-medium" open={open} onClose={onClose}>
      <DSDialogTitle>Final Confirmation</DSDialogTitle>
      <DSDialogContent>{message}</DSDialogContent>
      <DSDialogActions>
        <DSSecondaryButton onClick={onClose}>No</DSSecondaryButton>
        <DSPrimaryButton onClick={onConfirm}>Yes</DSPrimaryButton>
      </DSDialogActions>
    </DSDialog>
  );
};

export default ConfirmationModal;
