import { KlydoReviewStates, KlydoReviewStatus } from "../../api/klydo";
import DSModal from "../../views/system-design/DSModal";
import DSBox from "../../views/system-design/DSBox";
import DSTypography from "../../views/system-design/DSTypography";
import DSTextField from "../../views/system-design/DSTextField";
import useReviewFormModal from "../hooks/useReviewFormModal";
import DSPrimaryButton from "../../views/system-design/DSPrimaryButtom";
import DSSecondaryButton from "../../views/system-design/DSSecondaryButton";
import { ReviewFormStatus } from "../../Types";

export type ReviewModalProps = {
  open: boolean;
  handleClose: () => void;
  handleSubmitReview: (message: string) => void;
  review: ReviewFormStatus;
};

const KlydoReviewFormModal = ({
  open,
  handleClose,
  review,
  handleSubmitReview,
}: ReviewModalProps) => {
  const { message, setMessage, handleSubmit } = useReviewFormModal(
    handleClose,
    handleSubmitReview,
    review,
  );

  return (
    <DSModal
      open={open}
      onClose={handleClose}
      aria-labelledby="review-modal-title"
      aria-describedby="review-modal-description"
    >
      <DSBox
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          p: 4,
        }}
      >
        <DSTypography variant="h6" id="review-modal-title">
          Review Details
        </DSTypography>
        <DSTextField
          fullWidth
          label="Message"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          multiline
          rows={4}
          margin="normal"
        />
        <DSBox display="flex" justifyContent="space-between" mt={2}>
          <DSSecondaryButton onClick={handleClose}>Close</DSSecondaryButton>
          <DSPrimaryButton onClick={handleSubmit}>Submit</DSPrimaryButton>
        </DSBox>
      </DSBox>
    </DSModal>
  );
};

export default KlydoReviewFormModal;
