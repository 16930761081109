import { useState, useCallback } from "react";
import firebaseService from "../../../firebase_service/firebaseService";
import MsTable from "../../../components/Widgets/Table";
import CurrTime from "../../../components/Widgets/CurrTime";
import { Device, colType, DeviceValue } from "../../../Types";
import {
  resizeCloudinary,
  onlineCheck,
  ModalActions,
} from "../../../utils/util";
import DeviceModal from "./DeviceModal";
import bb from "../../../assets/img/Box_all.gif";
import { NavLink } from "react-router-dom";
import { Button, Form, InputGroup, Modal, Spinner } from "react-bootstrap";
import ScheduleBulkTaskModal from "../../ScheduleBulkTaskModal";
import { FaTrash } from "react-icons/fa";
import ButtonLoader from "../../../components/Widgets/ButtonLoader";
import { debounce } from "lodash";
import useClocksInfiniteQuery from "../hooks/useClocksInfiniteQuery";
import { Box, Typography } from "@mui/material";
import DSCheckbox from "../../system-design/DSCheckBox";

function SearchUnRegDevice() {
  const [devNotFoundErr, setDevNotFoundErr] = useState(false);
  const [unRegSerial, setUnRegSerial] = useState<string>();
  const [email, setEmail] = useState<string>();
  const [orderId, setOrderId] = useState<string>();
  const [unRegDevice, setUnRegDevice] = useState<Device>();
  const [showUnRegModal, setShowUnRegModal] = useState<boolean>(false);

  return (
    <>
      <Form noValidate style={{ display: "flex", flexDirection: "column" }}>
        {devNotFoundErr && <p style={{ color: "red" }}>Device Not Found</p>}
        <Form.Group
          controlId="formBasicPassword"
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          <InputGroup style={{ marginRight: "5px" }}>
            <Form.Control
              isInvalid={devNotFoundErr}
              placeholder="Serial or IDF..."
              required
              onChange={(e) => {
                setUnRegSerial(e.target.value);
              }}
              type="text"
            ></Form.Control>
          </InputGroup>

          <ButtonLoader
            title="search"
            disabled={!unRegSerial}
            style={{ color: "#0d6efd", fontSize: "12px", width: "50%" }}
            onClick={async () => {
              try {
                const res = await firebaseService.getDeviceBySerialOrIdf(
                  unRegSerial!,
                );
                setUnRegDevice(res);
                setShowUnRegModal(true);
              } catch (e) {
                setDevNotFoundErr(true);
                setTimeout(() => {
                  setDevNotFoundErr(false);
                }, 2000);
              }
            }}
          ></ButtonLoader>
        </Form.Group>
        <Form.Group
          controlId="formBasicPassword"
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          <InputGroup style={{ marginRight: "5px" }}>
            <Form.Control
              isInvalid={devNotFoundErr}
              placeholder="By email"
              required
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              type="text"
            ></Form.Control>
          </InputGroup>

          <ButtonLoader
            title="search"
            disabled={!email}
            style={{ color: "#0d6efd", fontSize: "12px", width: "50%" }}
            onClick={async () => {
              try {
                const res = await firebaseService.getDeviceByEmail(email!);
                setUnRegDevice(res);
                setShowUnRegModal(true);
              } catch (e) {
                setDevNotFoundErr(true);
                setTimeout(() => {
                  setDevNotFoundErr(false);
                }, 2000);
              }
            }}
          ></ButtonLoader>
        </Form.Group>
        <Form.Group
          controlId="formBasicPassword"
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          <InputGroup style={{ marginRight: "5px" }}>
            <Form.Control
              isInvalid={devNotFoundErr}
              placeholder="By order id"
              required
              onChange={(e) => {
                setOrderId(e.target.value);
              }}
              type="text"
            ></Form.Control>
          </InputGroup>

          <ButtonLoader
            title="search"
            disabled={!orderId}
            style={{ color: "#0d6efd", fontSize: "12px", width: "50%" }}
            onClick={async () => {
              try {
                const res = await firebaseService.getDeviceByOrderId(orderId!);
                setUnRegDevice(res);
                setShowUnRegModal(true);
              } catch (e) {
                setDevNotFoundErr(true);
                setTimeout(() => {
                  setDevNotFoundErr(false);
                }, 2000);
              }
            }}
          ></ButtonLoader>
        </Form.Group>
      </Form>
      {showUnRegModal && unRegDevice && (
        <Modal
          className="modal-big modal-primary"
          show={true}
          onHide={() => setShowUnRegModal(false)}
        >
          <DeviceModal setShowModal={setShowUnRegModal} row={unRegDevice} />
        </Modal>
      )}
    </>
  );
}

function Devices() {
  const [tableId] = useState("devices");
  const [allSelected, setAllSelected] = useState(false);
  const [allRemoved, setAllRemoved] = useState(false);
  const [selected, setSelected] = useState<Array<Device>>([]);
  const [currentSelected, setCurrentSelected] = useState(false);
  const [AggSelection, setAggSelection] = useState<Array<Device>>();
  const [filteredSelected, setFilteredSelected] = useState<Array<Device>>([]);
  const [userFilter, setUserFilter] = useState<Array<string>>([]);
  const [showOnlyDev, setShowOnlyDev] = useState(false);
  const [modalAction, setModalAction] = useState<ModalActions>(
    ModalActions.HIDE,
  );
  const [textSearch, setTextSearch] = useState<string>("");

  const {
    data: machinesNewData,
    fetchNextPage,
    isLoading,
  } = useClocksInfiniteQuery(textSearch, showOnlyDev);

  const handleFreeSearch = useCallback(
    debounce((s) => setTextSearch(s), 500),
    [],
  );

  const registerProductDate = (registerProduct: Date | DeviceValue<Date>) => {
    if (registerProduct instanceof Date) {
      return registerProduct?.toLocaleDateString(undefined, {
        year: "numeric",
        month: "numeric",
        day: "numeric",
      });
    }
    return new Date(registerProduct?.value)?.toLocaleDateString(undefined, {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    });
  };

  const tableColumns: Array<colType> = [
    {
      size: 40,
      noHeadline: true,
      sort: false,
      label: "Online",
      field: "online",
    },
    {
      sort: true,
      label: "Device ID",
      field: "idf",
    },
    {
      sort: true,
      label: "Location",
      field: "location",
    },
    {
      sort: true,
      label: "Heartbeat",
      field: "heartbeat",
    },
    {
      size: 90,
      sort: false,
      label: "Clock Time",
      field: "clockTime",
    },
    {
      sort: true,
      label: "Current Klydo id",
      field: "currentIdf",
      size: 300,
    },
    {
      sort: false,
      label: "Current Klydo image",
      field: "image",
    },
    {
      sort: true,
      label: "registred",
      field: "registerProduct",
    },
    {
      size: 48,
      sort: false,
      label: "Version",
      field: "version",
    },
  ];

  function genActions() {
    return (
      <div style={{ display: "flex" }}>
        <Button
          style={{
            marginBottom: "5px",
            marginRight: "5px",
            fontSize: "80%",
            height: "fit-content",
            padding: "6px",
            display: "flex",
            alignItems: "center",
          }}
          onClick={() => {
            setModalAction(ModalActions.DELETE);
          }}
        >
          {" "}
          Delete Klydos <FaTrash style={{ marginLeft: "5px" }} />
        </Button>

        <Button
          style={{
            marginBottom: "5px",
            marginRight: "5px",
            fontSize: "82%",
            height: "fit-content",
            padding: "6px",
            display: "flex",
            alignItems: "center",
          }}
          onClick={() => {
            setModalAction(ModalActions.UPDATE);
          }}
        >
          {" "}
          Update App{" "}
          <i style={{ marginLeft: "5px" }} className="nc-icon nc-android"></i>
        </Button>
        <Button
          style={{
            marginBottom: "5px",
            marginRight: "5px",
            fontSize: "82%",
            height: "fit-content",
            padding: "6px",
            display: "flex",
            alignItems: "center",
          }}
          onClick={() => {
            setModalAction(ModalActions.UPDATE_REMOTE);
          }}
        >
          {" "}
          Update Rmote App{" "}
          <i style={{ marginLeft: "5px" }} className="nc-icon nc-android"></i>
        </Button>

        <Button
          style={{
            marginBottom: "5px",
            marginRight: "5px",
            fontSize: "80%",
            height: "fit-content",
            padding: "6px",
            display: "flex",
            alignItems: "center",
          }}
          onClick={() => {
            setModalAction(ModalActions.PREVIEW);
          }}
        >
          Play Klydo{" "}
          <i
            style={{ marginLeft: "5px" }}
            className="nc-icon nc-button-play"
          ></i>
        </Button>

        <Button
          style={{
            marginBottom: "5px",
            marginRight: "5px",
            fontSize: "80%",
            height: "fit-content",
            display: "flex",
            alignItems: "center",
            padding: "6px",
          }}
          onClick={() => setModalAction(ModalActions.SYNC)}
        >
          Sync Pool{" "}
          <i
            style={{ marginLeft: "5px" }}
            className="nc-icon nc-refresh-02"
          ></i>{" "}
        </Button>
        <Button
          style={{
            marginBottom: "5px",
            marginRight: "5px",
            fontSize: "80%",
            height: "fit-content",
            display: "flex",
            alignItems: "center",
            padding: "6px",
          }}
          onClick={() => setModalAction(ModalActions.SYNC_LOGS)}
        >
          Sync Logs
        </Button>
        <Button
          style={{
            marginBottom: "5px",
            marginRight: "5px",
            fontSize: "80%",
            height: "fit-content",
            display: "flex",
            alignItems: "center",
            padding: "6px",
          }}
          onClick={() => setModalAction(ModalActions.UPDATE_LOG_CONFIG)}
        >
          Update Log Config
        </Button>
        <Button
          style={{
            marginBottom: "5px",
            marginRight: "5px",
            fontSize: "80%",
            height: "fit-content",
            display: "flex",
            alignItems: "center",
            padding: "6px",
          }}
          onClick={() => {
            if (selected.length > 1)
              return alert("Only one device can be selected for logcat");
            else setModalAction(ModalActions.LOGCAT);
          }}
        >
          Logcat
        </Button>
        <Button
          style={{
            marginBottom: "5px",
            marginRight: "5px",
            fontSize: "80%",
            height: "fit-content",
            display: "flex",
            alignItems: "center",
            padding: "6px",
          }}
          onClick={() => {
            if (selected.length > 1)
              return alert("Only one device can be selected for logcat");
            else setModalAction(ModalActions.ONBOARDING);
          }}
        >
          OnBoard
        </Button>
      </div>
    );
  }

  const imgOrVideo = (klydo: { id: string; loopUrl: string }) => {
    const parseCloudinaryUrl = (url: string) => {
      const type = url.split(".").pop();
      const params = "c_scale,w_150";
      if (type === "mp4") {
        url = url.replace("mp4", "jpg");
        if (url!.includes("image/upload") || url.includes("video/upload")) {
          url = url.replace("/upload/", `/upload/${params}/`);
        } else {
          url = url.replace("/klydoclock/", `/klydoclock/${params}/`);
        }
      }
      return url;
    };
    if (klydo.id === "9d371dd0-f44a-4d4d-8aeb-a927a788c978")
      return (
        <img
          style={{ borderRadius: 50 + "%" }}
          loading="lazy"
          height={96}
          width={96}
          src={bb}
          alt="klydo"
        />
      );
    const url = parseCloudinaryUrl(klydo.loopUrl);
    return (
      <img
        style={{ borderRadius: 50 + "%" }}
        loading="lazy"
        height={96}
        width={96}
        src={resizeCloudinary(url, 96)}
        alt="klydo"
      />
    );
  };
  const tableRows = (r: Device, key: number) => {
    const city = r.location?.city ? r.location.city : "no city";
    const country = r.location?.country_code
      ? r.location.country_code
      : "no country";
    return {
      idf: <p title={r.idf}>{r.idf}</p>,
      location: city + ", " + country,
      clockTime: r.clocktime ? (
        <CurrTime time={r.clocktime} offset={r.timeOffset?.value}></CurrTime>
      ) : (
        "-no info-"
      ),
      currentIdf: r.klydo && (
        <NavLink
          to="/admin/klydos"
          onClick={() => {
            localStorage.setItem("klydossearch", r.klydo!.id);
          }}
          title={r.klydo.id}
        >
          {r.id}
        </NavLink>
      ),
      image: r.klydo?.loopUrl ? imgOrVideo(r.klydo) : "-no img-",
      heartbeat: new Date(r.heartbeat?.value)?.toLocaleDateString(undefined, {
        year: "numeric",
        month: "numeric",
        day: "numeric",
      }),
      registerProduct: registerProductDate(r.registerProduct) || "-no info-",
      version: (
        <NavLink
          to={r.version ? "/admin/versions" : ""}
          onClick={() => {
            if (r.version) {
              localStorage.setItem(
                "versionssearch",
                r.version.toString() || "",
              );
            }
          }}
        >
          {r.version || "-no info-"}
        </NavLink>
      ),
      online: (
        <i
          className={`fa ${r.premium && r.premium.length ? "fa-star" : "fa-circle"}  ${onlineCheck(new Date(r.heartbeat?.value)) ? "text-success" : "text-danger"}`}
        ></i>
      ),
      key: key,
    };
  };

  return (
    <>
      <Form.Group style={{ marginRight: "20px" }}>
        <Form.Control
          onChange={(e) => {
            handleFreeSearch(e.target.value);
          }}
          type="search"
          placeholder="Device search"
        />
        <Box display={"flex"} alignItems={"center"}>
          <DSCheckbox
            checked={!!showOnlyDev}
            title="Only dev"
            onChange={(event) => {
              setShowOnlyDev(event.target.checked);
            }}
          />
          <Typography>Only dev</Typography>
        </Box>
      </Form.Group>
      {machinesNewData && !isLoading ? (
        <MsTable
          id={tableId}
          actions={() => genActions()}
          buttons={<SearchUnRegDevice />}
          onAction={(selectedDevices: Array<Device>) => {
            setSelected(selectedDevices);
            if (currentSelected) {
              if (allSelected) {
                setAllSelected(false);
                setCurrentSelected(false);
              } else if (allRemoved) {
                setAllRemoved(false);
                setCurrentSelected(false);
              } else {
                setUserFilter([]);
                return;
              }
              setUserFilter((prev) => prev.slice());
              setFilteredSelected(AggSelection!);
            }
          }}
          onSelectedChanged={(
            currSelected: Array<Device>,
            currTableData: Array<Device>,
          ) => {
            if (currSelected.length === 0) {
              setCurrentSelected(false);
              setUserFilter([]);
              return;
            }
            let isAllSelected: boolean = true;
            let isAllRemoved: boolean = true;

            for (let i = 0; i < currTableData.length; i++) {
              if (currSelected.includes(currTableData[i])) {
                isAllRemoved = false;
              } else {
                isAllSelected = false;
              }
              if (!isAllRemoved && !isAllSelected) break;
            }
            setAllSelected(isAllSelected);

            setAllRemoved(isAllRemoved);

            setCurrentSelected(
              isAllRemoved
                ? currTableData.some((device) =>
                    filteredSelected.includes(device),
                  )
                : true,
            );
            setAggSelection(currSelected);
          }}
          actionDisabled={(selection: Array<Device>) => {
            console.log(firebaseService.general?.maxMultipleCommand);
            if (
              firebaseService.general?.maxMultipleCommand &&
              selection.length <= firebaseService.general?.maxMultipleCommand
            )
              return;
            return `The multiple commands feature is limited to ${firebaseService.general?.maxMultipleCommand} devices (to increase limit contact the dev team)`;
          }}
          rowBuild={tableRows}
          initSort=""
          data={machinesNewData!}
          cols={tableColumns}
          title="Devices"
          modal={DeviceModal}
          onScrollDown={fetchNextPage}
          shouldHideSearchBar={true}
        />
      ) : (
        <Spinner></Spinner>
      )}
      {modalAction !== ModalActions.HIDE && (
        <ScheduleBulkTaskModal
          open={true}
          userFilter={userFilter!}
          handleClose={() => {
            setModalAction(ModalActions.HIDE);
          }}
          action={modalAction!}
          selectedDevices={selected}
        />
      )}
    </>
  );
}

export default Devices;
