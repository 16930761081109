import DSCircularProgress from "../../system-design/DSCircularProgress";
import DSBox from "../../system-design/DSBox";
import DSTypography from "../../system-design/DSTypography";
import usePacksReviewedList from "../hooks/usePacksReviewedList";
import { DSBaseTable, DSTableCell } from "../../system-design/Table";
import { DraftPack } from "../../../Types";
import { Column } from "../../system-design/Table/DSBaseTable/components/DSBaseTable";
import DSTextField from "../../system-design/DSTextField";
import PacksListAvatar from "./PacksListAvatar";
import PackReviewModal from "./PackReviewModal";

const PacksReview = () => {
  const {
    data: packs,
    fetchNextPage,
    isLoading,
    columns,
    handleSelectRow,
    handleTextSearch,
    handleCloseModal,
    isModalOpen,
    selectedPack,
  } = usePacksReviewedList();

  const buildTableRows = (pack: DraftPack, columns: Column<DraftPack>[]) => (
    <>
      {columns.map((col) => (
        <DSTableCell
          key={col.field as string}
          style={{
            width: col.size || `${100 / columns?.length}%`,
            whiteSpace: "nowrap",
          }}
        >
          {col.field === "cover" ? (
            <DSBox>
              <PacksListAvatar packCover={pack.cover} />
            </DSBox>
          ) : col.field === "reviewStatus" ? (
            pack.reviewStatus?.state || "N/A"
          ) : (
            String(pack[col.field])
          )}
        </DSTableCell>
      ))}
    </>
  );

  return (
    <>
      <DSBox mb={3} sx={{ marginRight: "20px" }}>
        <DSTypography variant="h6" mb={1}>
          Free Search
        </DSTypography>
        <DSTextField
          fullWidth
          variant="outlined"
          placeholder="Search Packs"
          onChange={(e) => {
            handleTextSearch(e.target.value);
          }}
        />
      </DSBox>
      {packs && !isLoading ? (
        <>
          <DSBaseTable
            columns={columns}
            fetchMore={fetchNextPage}
            rowBuilder={buildTableRows}
            data={packs}
            onPressRow={(row) => handleSelectRow(row)}
          />
          {selectedPack && (
            <PackReviewModal
              isModalOpen={isModalOpen}
              onClose={handleCloseModal}
              pack={selectedPack}
            />
          )}
        </>
      ) : (
        !isLoading && (
          <DSTypography variant="body1" textAlign="center">
            <p>No Packs found in review</p>
          </DSTypography>
        )
      )}
      {isLoading && (
        <DSBox
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <DSCircularProgress />
        </DSBox>
      )}
    </>
  );
};

export default PacksReview;
