import { useState } from "react";
import {
  Alert,
  Modal,
  Container,
  Card,
  Row,
  Col,
  Button,
  Form,
} from "react-bootstrap";
import firebaseService from "firebase_service/firebaseService";
import ButtonLoader from "components/Widgets/ButtonLoader";
import { myColor } from "../utils/util";
import { Klydo_deprecated } from "Types";
import TimeControl from "components/Widgets/TimeControl";
import ClockView from "components/Widgets/ClockView";
import { FaCheck, FaEdit } from "react-icons/fa";
import { FaX } from "react-icons/fa6";
import Editable from "components/Widgets/Editable";
import { NavLink } from "react-router-dom";
import SimpleSwitch from "components/Widgets/SimpleSwitch";

function ReviewModal(props: any) {
  const setShowModal = props.setShowModal;
  const review = props.row as Klydo_deprecated;
  const [innerModal, setInnerModal] = useState<
    "hide" | "reject" | "accept" | "delete"
  >("hide");
  const [alert, setAlert] = useState<string>();
  const [rejection, setRejection] = useState("");
  const [cahngeName, setCahngeName] = useState(false);
  const [klydoName, setKlydoName] = useState(review.name);
  const [klydo] = useState<Klydo_deprecated>(props.row);
  const [showDials, setShowDials] = useState<boolean>(klydo.dials ?? true);
  const [dialsColor, setDialsColor] = useState(
    klydo.theme.dialsColor || klydo.theme.handsColor,
  );

  function getContrastYIQ(hexcolor: string) {
    if (!hexcolor) return;
    var r = parseInt(hexcolor.substring(1, 3), 16);
    var g = parseInt(hexcolor.substring(3, 5), 16);
    var b = parseInt(hexcolor.substring(5, 7), 16);
    var yiq = (r * 299 + g * 587 + b * 114) / 1000;
    return yiq >= 128 ? "black" : "white";
  }

  return (
    <>
      <Modal.Header style={{ marginLeft: 24, marginTop: 0, marginBottom: 0 }}>
        <h4>Review Info</h4>
        <i
          className="nc-icon nc-simple-remove"
          style={{ marginTop: 15 }}
          onClick={() => setShowModal(false)}
        ></i>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          <div
            style={{
              display: "flex",
              justifyContent: "left",
              alignItems: "center",
              marginBottom: 15,
            }}
          >
            {review.review.type === "delete" && (
              <h3>
                The author has requested this klydo to be removed from public.
                Accept to remove
              </h3>
            )}
            {review.review.type === "edit" && (
              <h3>
                The author has requested this klydo to be public. Accept to
                publish
              </h3>
            )}
            <FaCheck
              size={25}
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                margin: "10 10 0 10",
                color:
                  review.review.type === "edit" && !review.zip
                    ? "lightgray"
                    : "black",
              }}
              onClick={() =>
                review.review.type === "edit" && review.zip
                  ? setInnerModal("accept")
                  : review.review.type === "delete"
                    ? setInnerModal("delete")
                    : null
              }
            />
            <FaX
              size={25}
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                margin: "10 10 0 10",
              }}
              onClick={() => setInnerModal("reject")}
            />
          </div>
          <Row>
            <Col lg="6" sm="6">
              <Card
                className="card-stats shadow"
                style={{ background: myColor }}
              >
                <Card.Header
                  as="h4"
                  style={{ marginTop: 0, background: myColor }}
                >
                  Info
                </Card.Header>
                <hr style={{ margin: 0 }} />
                <Card.Body>
                  <Row>
                    <Col className="pr-1" md="12">
                      <h5 className="pl-2" style={{ display: "inline" }}>
                        <b>Review ID:</b> {review.id}
                      </h5>
                    </Col>
                    <Col className="pr-1" md="12" style={{ marginBottom: 22 }}>
                      <h5
                        className="pl-2"
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <b>Name:</b>
                        {cahngeName ? (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              width: "500px",
                              minWidth: "200px",
                              maxWidth: "700px",
                            }}
                          >
                            <Form.Control
                              style={{ marginLeft: "12px" }}
                              value={klydoName}
                              onChange={(e) => setKlydoName(e.target.value)}
                            ></Form.Control>
                            <ButtonLoader
                              variant="outline-dark"
                              title={<i className="nc-icon nc-check-2"></i>}
                              onClick={() => {
                                return new Promise<void>((v, x) => {
                                  firebaseService
                                    .changeKlydoName(klydo, klydoName, true)
                                    .then(() => {
                                      setCahngeName(false);
                                      v();
                                    })
                                    .catch(x);
                                });
                              }}
                            />
                            <i
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                flexDirection: "column",
                              }}
                              className="nc-icon nc-simple-remove"
                              onClick={() => {
                                setKlydoName(klydo.name);
                                setCahngeName(false);
                              }}
                            />
                          </div>
                        ) : (
                          <>
                            {klydoName}
                            <FaEdit
                              size={20}
                              style={{ marginLeft: 10 }}
                              onClick={(e: any) => {
                                e.stopPropagation();
                                setCahngeName(true);
                              }}
                            />
                          </>
                        )}
                      </h5>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
              <Card
                className="card-stats shadow"
                style={{ background: myColor }}
              >
                <Card.Header
                  as="h4"
                  style={{ marginTop: 0, background: myColor }}
                >
                  Author
                </Card.Header>
                <hr style={{ margin: 0 }} />
                <Card.Body>
                  <Col className="pr-1" md="12">
                    <b>Author:</b>
                    <NavLink
                      to="/admin/users"
                      className="pl-2"
                      style={{ display: "inline" }}
                      onClick={() => {
                        localStorage.setItem("userssearch", review.author);
                      }}
                    >
                      {review.author}
                    </NavLink>
                  </Col>
                  <Col className="pr-1" md="12" style={{ marginBottom: 22 }}>
                    <h5 className="pl-2" style={{ display: "inline" }}>
                      <b>Author ID:</b> {review.creator}
                    </h5>
                  </Col>
                </Card.Body>
              </Card>
              <Card
                className="card-stats shadow"
                style={{ background: myColor }}
              >
                <Card.Header
                  as="h4"
                  style={{ marginTop: 0, background: myColor }}
                >
                  Theme
                </Card.Header>
                <hr style={{ margin: 0 }} />
                <Card.Body>
                  <Col className="pr-1" md="12" style={{ marginBottom: 22 }}>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <b>Background Color:</b>
                      <Editable
                        title="color"
                        default={klydo.theme.backgroundColor}
                        onSet={(value) =>
                          firebaseService.changeTheme(
                            "backgroundColor",
                            klydo,
                            value,
                            true,
                          )
                        }
                        editIcon={
                          <FaEdit size={20} style={{ marginLeft: 10 }} />
                        }
                        viewAs={
                          <span
                            className="dot"
                            style={{
                              backgroundColor: klydo.theme.backgroundColor,
                              color: getContrastYIQ(
                                klydo.theme.backgroundColor,
                              ),
                            }}
                          >
                            {klydo.theme.backgroundColor}
                          </span>
                        }
                      />
                    </div>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <b>Hands Color:</b>
                      <Editable
                        title="color"
                        default={klydo.theme.handsColor}
                        onSet={(value) =>
                          firebaseService.changeTheme(
                            "handsColor",
                            klydo,
                            value,
                            true,
                          )
                        }
                        editIcon={
                          <FaEdit size={20} style={{ marginLeft: 10 }} />
                        }
                        viewAs={
                          <span
                            className="dot"
                            style={{
                              backgroundColor: klydo.theme.handsColor,
                              color: getContrastYIQ(klydo.theme.handsColor),
                            }}
                          >
                            {klydo.theme.handsColor}
                          </span>
                        }
                      />
                    </div>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <b>Pendulum Color:</b>
                      <Editable
                        title="color"
                        default={klydo.theme.pendulumColor}
                        onSet={(value) =>
                          firebaseService.changeTheme(
                            "pendulumColor",
                            klydo,
                            value,
                            true,
                          )
                        }
                        editIcon={
                          <FaEdit size={20} style={{ marginLeft: 10 }} />
                        }
                        viewAs={
                          <span
                            className="dot"
                            style={{
                              backgroundColor: klydo.theme.pendulumColor,
                              color: getContrastYIQ(klydo.theme.pendulumColor),
                            }}
                          >
                            {klydo.theme.pendulumColor}
                          </span>
                        }
                      />
                    </div>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <b>Pendulum Rod Color:</b>
                      <Editable
                        title="color"
                        default={klydo.theme.pendulumRodColor}
                        onSet={(value) =>
                          firebaseService.changeTheme(
                            "pendulumRodColor",
                            klydo,
                            value,
                            true,
                          )
                        }
                        editIcon={
                          <FaEdit size={20} style={{ marginLeft: 10 }} />
                        }
                        viewAs={
                          <span
                            className="dot"
                            style={{
                              backgroundColor: klydo.theme.pendulumRodColor,
                              color: getContrastYIQ(
                                klydo.theme.pendulumRodColor,
                              ),
                            }}
                          >
                            {klydo.theme.pendulumRodColor}
                          </span>
                        }
                      />
                    </div>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div style={{ display: "flex" }}>
                        <b>Dials Color:</b>
                        {showDials && (
                          <Editable
                            title="color"
                            default={dialsColor}
                            onSet={(value) => {
                              setDialsColor(value);
                              return firebaseService.changeTheme(
                                "dialsColor",
                                klydo,
                                value,
                                true,
                              );
                            }}
                            editIcon={
                              <FaEdit size={20} style={{ marginLeft: 10 }} />
                            }
                            viewAs={
                              <span
                                className="dot"
                                style={{
                                  backgroundColor: dialsColor,
                                  color: getContrastYIQ(dialsColor),
                                }}
                              >
                                {dialsColor}
                              </span>
                            }
                          />
                        )}
                      </div>
                      <SimpleSwitch
                        title="Dials"
                        on={undefined}
                        default={showDials}
                        onClick={(on) =>
                          firebaseService.setDials(klydo, on, true).then(() => {
                            klydo.dials = on;
                            setShowDials(on);
                          })
                        }
                      />
                    </div>
                  </Col>
                </Card.Body>
              </Card>
              <Card
                className="card-stats shadow"
                style={{ background: myColor }}
              >
                <Card.Header
                  as="h4"
                  style={{ marginTop: 0, background: myColor }}
                >
                  Times
                </Card.Header>
                <hr style={{ margin: 0 }} />
                <Card.Body>
                  <TimeControl klydo={review} editable={true} isReview={true} />
                </Card.Body>
              </Card>
            </Col>
            <Col lg="6" sm="6">
              <Col className="pr-1" md="12" style={{ marginBottom: 22 }}>
                <div
                  style={{
                    width: "476px",
                    height: "785px",
                    position: "relative",
                  }}
                >
                  <ClockView
                    loopUrl={review.loopUrl}
                    themeID={review.id}
                    timeShift={0}
                    handsColor={review.theme.handsColor}
                    pendulumColor={review.theme.pendulumColor}
                    pendulumRodColor={review.theme.pendulumRodColor}
                    backgroundColor={review.theme.backgroundColor}
                    dialsColor={dialsColor}
                    crop={review.crop}
                    showDials={showDials}
                  />
                </div>
              </Col>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <div className="modal-footer justify-content-end">
        <Button
          className="btn-simple right"
          type="button"
          variant="link"
          onClick={() => setShowModal(false)}
        >
          Close
        </Button>
      </div>
      <Modal
        className="modal-medium"
        show={innerModal !== "hide"}
        onHide={() => setInnerModal("hide")}
      >
        <Modal.Body>
          {innerModal === "hide" ? (
            <></>
          ) : innerModal === "reject" ? (
            <>
              <Form>
                <Row>
                  <Form.Label as="h5" style={{ paddingTop: 12 }}>
                    <b>add rejuction reason:</b>
                  </Form.Label>
                </Row>
                <Row className="h-100">
                  <Form.Control
                    as="textarea"
                    rows={4}
                    value={rejection}
                    onChange={(e) => {
                      setRejection(e.target.value);
                    }}
                  ></Form.Control>
                </Row>
              </Form>
              <Row className="justify-content-between">
                <Col sm="5">
                  <Button onClick={() => setInnerModal("hide")}>Cancel</Button>
                </Col>
                <Col sm="5">
                  <Button
                    onClick={() => {
                      if (!rejection) {
                        setAlert("you must provide rejection reason");
                        setTimeout(() => {
                          setAlert(undefined);
                        }, 4000);
                        return;
                      }
                      firebaseService
                        .acceptReview(review, false, rejection)
                        .then(() => {
                          setInnerModal("hide");
                          setShowModal(false);
                        })
                        .catch((e) => {
                          setAlert("failed to reject, " + e.message);
                          setTimeout(() => {
                            setAlert(undefined);
                          }, 4000);
                        });
                    }}
                  >
                    Reject
                  </Button>
                </Col>
              </Row>
            </>
          ) : (
            <>
              <Row style={{ margin: 25 }}>
                <h4>
                  Are you sure you want to{" "}
                  {innerModal === "accept" ? "make it" : "remove it from"}{" "}
                  public?
                </h4>
                <h5>{review?.name}</h5>
              </Row>
              <Row className="justify-content-between">
                <Col sm="5">
                  <Button
                    className="w-100 h-100"
                    onClick={() => setInnerModal("hide")}
                  >
                    No
                  </Button>
                </Col>
                <Col sm="5">
                  <ButtonLoader
                    title="YES"
                    className="w-100 h-100"
                    onClick={() => {
                      return new Promise<void>((v, x) => {
                        firebaseService
                          .acceptReview(
                            review,
                            innerModal === "delete",
                            innerModal === "delete" ? "deleted" : undefined,
                          )
                          .then(() => {
                            setInnerModal("hide");
                            setShowModal(false);
                          })
                          .catch(() => {
                            setAlert(
                              `failed to ${innerModal === "accept" ? "publish" : "delete"}`,
                            );
                            setTimeout(() => {
                              setAlert(undefined);
                            }, 4000);
                          })
                          .finally(v);
                      });
                    }}
                  />
                </Col>
              </Row>
            </>
          )}
          <Alert show={!!alert} variant="danger">
            <h5 style={{ margin: 2 }}>{alert}</h5>
          </Alert>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ReviewModal;
