import { useMutation, useQueryClient } from "@tanstack/react-query";
import klydoApiClient from "../../../api/klydo";
import { Klydo_deprecated } from "../../../Types";

const useKlydoMutation = (klydoId: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ["klydo", klydoId],
    mutationFn: (klydo: Partial<Klydo_deprecated>) =>
      klydoApiClient.updateKlydo(klydoId, klydo),
    onSuccess: () =>
      queryClient.refetchQueries({
        queryKey: ["klydo", klydoId],
      }),
  });
};

export default useKlydoMutation;
