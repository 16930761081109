import { useState, useEffect } from "react";
import MsTable from "../components/Widgets/Table";
import featureFlagsService from "../firebase_service/featureFlagsService";
import FeatureModal from "./FeatureModal";
import { Alert, Button, Form, Modal } from "react-bootstrap";
import { FaTrash } from "react-icons/fa";
import { colType } from "../Types";
import FloatAddButton from "../components/Widgets/FloatAddButton";

export type Feature = {
  name: string;
  description: string;
  value: string;
};

export type FeatureRow = {
  name: string;
  description: string;
  value: string;
  delete: JSX.Element;
};
function FeatureFlags() {
  const [features, setFeatures] = useState<Feature[]>([]);
  const [showAddFeature, setShowAddFeature] = useState<boolean>(false);
  const [showDelete, setShowDelete] = useState<boolean>(false);
  const [featureToDelete, setFeatureToDelete] = useState<Feature>();

  useEffect(() => {
    async function fetchFeatures() {
      const featuresData =
        await featureFlagsService.getAllFeaturesFromGeneral();
      const formattedFeatures = featuresData
        ? Object.entries(featuresData).map(([key, value]) => ({
            name: key,
            description: "Feature description here",
            value: value,
          }))
        : [];
      setFeatures(formattedFeatures);
    }
    fetchFeatures();
  }, []);

  const removeFeature = async (featureName: string) => {
    await featureFlagsService
      .removeFeatureFromGeneralFeatures(featureName)
      .then(() => {
        setFeatures((prevFeatures) =>
          prevFeatures.filter((f) => f.name !== featureName),
        );
      });
  };

  const tableColumns: Array<colType> = [
    { label: "Feature Name", field: "name", sort: true },
    { label: "Description", field: "description", sort: false },
    { label: "Value", field: "value", sort: true },
    {
      size: 32,
      noHeadline: true,
      sort: false,
      label: "",
      field: "delete",
    },
  ];

  const tableRows = (feature: Feature): FeatureRow => {
    return {
      name: feature.name,
      description: feature.description,
      value: feature.value,
      delete: (
        <FaTrash
          style={{ margin: "8px" }}
          className="nc-icon nc-simple-remove"
          onClick={(e) => {
            e.stopPropagation();
            setFeatureToDelete(feature);
            setShowDelete(true);
          }}
        />
      ),
    };
  };

  const DeleteFeatureModal = () => {
    return (
      <>
        {featureToDelete && (
          <Modal onHide={() => setShowDelete(false)} show={showDelete}>
            <Modal.Header closeButton>
              <Modal.Title>Confirm Deletion</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              "Are you sure you want to delete the feature flag?"
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setShowDelete(false)}>
                Cancel
              </Button>
              <Button
                variant="danger"
                onClick={() => {
                  removeFeature(featureToDelete.name);
                  setShowDelete(false);
                }}
              >
                Delete
              </Button>
            </Modal.Footer>
          </Modal>
        )}
      </>
    );
  };

  const AddNewFeatureModal = () => {
    const [newFeatureName, setNewFeatureName] = useState("");
    const [newFeatureValue, setNewFeatureValue] = useState("");
    const [showWarning, setShowWarning] = useState(false);
    const addNewFeature = async () => {
      if (!newFeatureName || !newFeatureValue) {
        setShowWarning(true);
        return;
      }

      setShowWarning(false);
      await featureFlagsService.updateFeatureToGeneralFeatures(
        newFeatureName,
        newFeatureValue,
      );
      const featuresData =
        await featureFlagsService.getAllFeaturesFromGeneral();
      setFeatures(
        featuresData
          ? Object.entries(featuresData).map(([key, value]) => ({
              name: key,
              description: "Feature description here",
              value: value,
            }))
          : [],
      );
    };
    return (
      <>
        <Modal.Header>
          <Modal.Title>Add New Feature</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {showWarning && (
            <Alert variant="danger">
              Both Feature Name and Feature Value are required!
            </Alert>
          )}
          <Form>
            <Form.Group controlId="formFeatureName">
              <Form.Label>Feature Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter feature name"
                value={newFeatureName}
                onChange={(e) => setNewFeatureName(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="formFeatureValue">
              <Form.Label>Feature Value</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter feature value"
                value={newFeatureValue}
                onChange={(e) => setNewFeatureValue(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowAddFeature(false)}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              addNewFeature();
              setShowAddFeature(false);
            }}
          >
            Add Feature
          </Button>
        </Modal.Footer>
      </>
    );
  };

  return (
    <>
      {features && (
        <>
          <MsTable
            id="Feature Flags"
            data={features}
            cols={tableColumns}
            rowBuild={tableRows}
            title="Feature Flags"
            search={(row: FeatureRow, val: string) => {
              return (
                row.name.includes(val) ||
                row.description.includes(val) ||
                row.value.toLowerCase().includes(val)
              );
            }}
            initSort={"name"}
            modal={FeatureModal}
          />
          <FloatAddButton onClick={() => setShowAddFeature(true)} />
        </>
      )}

      {showDelete && <DeleteFeatureModal />}
      {showAddFeature && (
        <Modal show={true} size="xl" onHide={() => setShowAddFeature(false)}>
          <AddNewFeatureModal />
        </Modal>
      )}
    </>
  );
}

export default FeatureFlags;
