import { Command, Device, Klydo_deprecated } from "Types";
import { Button, Col, ListGroup, Modal, Row, Spinner } from "react-bootstrap";
import { BsCheck } from "react-icons/bs";
import { isVideo, resizeCloudinary } from "../utils/util";
import firebaseService from "../firebase_service/firebaseService";
import { useEffect, useState } from "react";
import ButtonLoader from "components/Widgets/ButtonLoader";
import id from "friendly-id/id-gen";

function CommandModal({
  open,
  handleClose,
  command,
}: {
  open: boolean;
  handleClose: (b: boolean) => void;
  command: Command;
}) {
  const [showCmdDeleteModal, setShowCmdDeleteModal] = useState(false);
  const [devices, setDevices] = useState<Array<Device>>();
  const [klydos, setKlydos] = useState<Array<Klydo_deprecated>>();
  const [over, setOver] = useState(false);

  useEffect(() => {
    if (command.devices)
      firebaseService
        .getList<Device>("machines", "id", command.devices! as string[])
        .then((v) => setDevices(v.map((item) => item as Device)));
    if (
      command.params &&
      (command.name.includes("delete") || command.name.includes("play"))
    )
      firebaseService
        .getList<Klydo_deprecated>("klydos", "id", command.params)
        .then((v) =>
          setKlydos(
            v.map(
              (item) =>
                ({
                  ...item,
                  idf: id.friendlyFromHex(item.id),
                }) as Klydo_deprecated,
            ),
          ),
        );
  }, []);
  function minutesToTime(minNum: number) {
    let days = Math.floor(minNum / 1440);
    let hours = Math.floor((minNum % 1440) / 60);
    let minutes = Math.floor(minNum - hours * 60 - days * 1440);
    const daysString = days < 10 ? "0" + days : days.toString();
    const hoursString = hours < 10 ? "0" + hours : hours.toString();
    const minutesString = minutes < 10 ? "0" + minutes : minutes.toString();

    return daysString + "d:" + hoursString + "h:" + minutesString + "m";
  }

  function getHeadline(name: string) {
    switch (name) {
      case "deleteKlydos":
        return "Delete";
      case "updateApp":
        return "Update";
      case "playKlydo":
        return "Play";
      case "syncLogs":
        return "Sync Logs";
      case "updateLogConfig":
        return "Update Log Config";
      case "logcat":
        return "Logcat";
    }
  }

  return (
    <Modal show={open} onHide={() => handleClose(false)}>
      <Modal.Header
        style={{
          padding: 0,
          marginLeft: "10px",
          marginRight: "5px",
          marginBottom: "24px",
        }}
      >
        <h4
          style={{
            margin: 0,
            paddingTop: "16px",
            paddingLeft: "14px",
            fontWeight: "bold",
          }}
        >
          {getHeadline(command.name)}
        </h4>
        {
          <i
            className="nc-icon nc-simple-remove btn"
            style={{ bottom: "7px", position: "relative", padding: 0 }}
            onClick={() => handleClose(false)}
          ></i>
        }
      </Modal.Header>

      <Modal.Body>
        <p style={{ fontWeight: "bold", margin: 0 }}>Scheduled for:</p>{" "}
        <p>
          {" "}
          {command.time.toUTCString().replace("GMT", "")}{" "}
          {command.local ? "CLT (Clock Local Time)" : "GMT"}
        </p>
        {devices?.length! > 0 ? (
          <>
            <p style={{ margin: 0, marginBottom: "5px", fontWeight: "bold" }}>
              Devices:
            </p>
            <ListGroup
              style={{ borderRadius: 0, border: "none" }}
              className="border-0"
            >
              <ListGroup.Item
                style={{
                  border: 0,
                  height: "90%",
                  padding: 0,
                  marginTop: "10px",
                }}
              >
                <div
                  style={{
                    fontSize: "12px",
                    color: "gray",
                    border: 0,
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div style={{ width: 105 }} className="mb-1">
                    IDF
                  </div>
                  <div style={{ width: 50 }} className="mb-1">
                    Location
                  </div>
                  <div
                    style={{ width: 50, position: "relative" }}
                    className="mb-1"
                  >
                    Version
                  </div>
                  <div
                    style={{ width: 50, position: "relative" }}
                    className="mb-1"
                  >
                    Tag
                  </div>
                </div>
              </ListGroup.Item>
            </ListGroup>
            <ListGroup
              style={{ borderRadius: 0, border: "none", maxHeight: "80px" }}
              className="border-0"
            >
              {devices!.map((device) => (
                <ListGroup.Item
                  key={device.id}
                  style={{ border: 0, paddingTop: 0, padding: 0 }}
                >
                  <div
                    style={{
                      fontSize: "12px",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div style={{ width: 105 }} title={device.id}>
                      {device.idf || "--"}
                    </div>
                    <div style={{ width: 50 }}>
                      {device.location
                        ? device.location.city +
                          ", " +
                          device.location.country_code
                        : ""}
                    </div>
                    <div style={{ width: 50 }}>{device.version || "--"}</div>
                    <div style={{ width: 50 }}>{device.tag?.value || "--"}</div>
                  </div>
                </ListGroup.Item>
              ))}
            </ListGroup>
          </>
        ) : devices ? (
          <p>Devices not available</p>
        ) : (
          command.devices && <Spinner />
        )}
        {command.filter && (
          <>
            <p style={{ textAlign: "center" }}>
              Command will be sent to devices accrding to the following fitler:
            </p>
            <div style={{ overflowY: "auto", maxHeight: "80px" }}>
              {command.filter.map((f, index) => (
                <p
                  style={{ fontSize: "12px", textAlign: "center" }}
                  key={index}
                >
                  {f}
                </p>
              ))}
            </div>
          </>
        )}
        {command.timeout && (
          <>
            <p style={{ fontWeight: "bold", margin: 0 }}>
              Command will expire for devices who wont receive it within the
              selected time frame:{" "}
            </p>{" "}
            <p>{minutesToTime(command.timeout)}</p>
          </>
        )}
        {klydos?.length! > 0 ? (
          <>
            <p style={{ margin: 0, marginBottom: "5px", fontWeight: "bold" }}>
              Selected klydos:
            </p>
            <ListGroup
              style={{ borderRadius: 0, border: "none" }}
              className="border-0"
            >
              <ListGroup.Item
                style={{
                  border: 0,
                  height: "90%",
                  padding: 0,
                  marginTop: "10px",
                }}
              >
                <div
                  style={{
                    fontSize: "12px",
                    color: "gray",
                    border: 0,
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div style={{ width: 105 }} className="mb-1">
                    IDF
                  </div>
                  <div style={{ width: 105 }} className="mb-1">
                    Author
                  </div>
                  <div
                    style={{ width: 35, left: "7px", position: "relative" }}
                    className="mb-1"
                  >
                    GIF
                  </div>
                  <div
                    style={{ width: 12, right: "13px", position: "relative" }}
                    className="mb-1"
                  >
                    Pool
                  </div>
                </div>
              </ListGroup.Item>
            </ListGroup>
            <ListGroup
              style={{
                borderRadius: 0,
                border: "none",
                maxHeight: "80px",
                overflowY: "auto",
              }}
              className="border-0"
            >
              {klydos!.map((klydo) => (
                <ListGroup.Item
                  key={klydo?.id || ""}
                  style={{ border: 0, paddingTop: 0, padding: 0 }}
                >
                  <div
                    style={{
                      fontSize: "12px",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div style={{ width: 105 }} title={klydo?.id || ""}>
                      {klydo?.idf || "--"}
                    </div>
                    <div style={{ width: 105 }} title={klydo?.creator || ""}>
                      {klydo?.author || "--"}
                    </div>
                    {!klydo?.loopUrl || isVideo(klydo?.loopUrl) === "none" ? (
                      <div
                        style={{
                          width: 35,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <p>--</p>
                      </div>
                    ) : (
                      <div
                        style={{
                          borderRadius: "50%",
                          width: 35,
                          height: 35,
                          position: "relative",
                          overflow: "hidden",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            position: "absolute",
                            top: klydo?.crop?.top || 0 + "%",
                            left: klydo?.crop?.left || 0 + "%",
                            width: `${klydo?.crop?.zoom || 100}%`,
                            aspectRatio: "1/1",
                          }}
                        >
                          {isVideo(klydo?.loopUrl) === "image" ? (
                            <img
                              loading="lazy"
                              alt="gif"
                              src={resizeCloudinary(klydo?.loopUrl, 48)}
                              style={{
                                width: "auto",
                                height: "100%",
                                objectFit: "cover",
                              }}
                            />
                          ) : (
                            <video
                              muted={true}
                              src={resizeCloudinary(klydo?.loopUrl, 48)}
                              loop
                              autoPlay
                              style={{
                                width: "auto",
                                height: "100%",
                                objectFit: "cover",
                              }}
                            />
                          )}
                        </div>
                      </div>
                    )}
                    {klydo?.pool ? (
                      <BsCheck style={{ width: 12 }} color="black"></BsCheck>
                    ) : (
                      <div style={{ width: 12 }}></div>
                    )}
                  </div>
                </ListGroup.Item>
              ))}
            </ListGroup>
          </>
        ) : klydos ? (
          <p>Klydos not available</p>
        ) : (
          command.klydos && <Spinner />
        )}
        <div
          style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}
        >
          {(() => {
            let prevDaySched = new Date(command.time);
            let currentTime = new Date();
            prevDaySched.setDate(prevDaySched.getDate() - 1);
            prevDaySched.setHours(23, 45, 0, 0);
            return currentTime.getTime() < prevDaySched.getTime();
          })() && (
            <Button
              onMouseOver={(i) => setOver(true)}
              onMouseOut={(i) => setOver(false)}
              className={`h-100 form-control ${over ? "is-invalid" : ""}`}
              style={{
                width: "23%",
                marginTop: "20px",
                padding: 0,
                height: 30,
                color: "red",
                border: "1px solid red",
                marginRight: "10px",
              }}
              title="Delete command"
              onClick={() => {
                setShowCmdDeleteModal(true);
              }}
            >
              Delete
            </Button>
          )}
          <Button
            className="btn h-100"
            style={{ marginTop: "20px", padding: 0, width: "15%", height: 30 }}
            title="Close"
            onClick={() => {
              handleClose(false);
            }}
          >
            Ok
          </Button>
        </div>
      </Modal.Body>
      {showCmdDeleteModal && (
        <Modal
          className="modal-medium"
          show={showCmdDeleteModal}
          onHide={() => setShowCmdDeleteModal(false)}
        >
          <Modal.Body>
            <Row style={{ margin: 25 }}>
              <h4 style={{ textAlign: "center" }}>
                Are you sure you want to delete this command?
              </h4>
            </Row>
            <Row className="justify-content-between">
              <Col sm="5">
                <Button
                  className="w-100 h-100"
                  onClick={() => setShowCmdDeleteModal(false)}
                >
                  No
                </Button>
              </Col>
              <Col sm="5">
                <ButtonLoader
                  title="YES"
                  className="w-100 h-100"
                  onClick={() => {
                    return new Promise<void>((v, x) => {
                      firebaseService
                        .deleteScheduledCommand(command)
                        .then(() => {
                          setShowCmdDeleteModal(false);
                          handleClose(false);
                        })
                        .catch((e) => {
                          console.log("error: " + e);
                        })
                        .finally(v);
                    });
                  }}
                />
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
      )}
    </Modal>
  );
}

export default CommandModal;
