import { useMutation, useQueryClient } from "@tanstack/react-query";
import giftCategoriesClient, {
  EditGiftInput,
  Gift,
} from "../../../../api/giftCategories";

const useOrderGiftMutation = (orderId: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (input: Pick<EditGiftInput, "clocksIds">) =>
      giftCategoriesClient.editOrderGift({ orderId, ...input }),
    onSuccess: (gift: Gift) => {
      queryClient.setQueryData(["order", orderId], gift);
      queryClient.setQueryData(["giftOrders"], (giftOrders: Gift[]) => {
        return giftOrders.map((order) =>
          order.orderId === orderId ? gift : order,
        );
      });
    },
  });
};

export default useOrderGiftMutation;
