import axios from "axios";
import firebaseService from "../firebase_service/firebaseService";

export const baseUrl = "https://api.klydoclock.com";
// Unslash this for local debugging
// export const baseUrl = "http://localhost:10010";

export const getToken = async () => {
  return firebaseService.getToken();
};

export const getUserId = async () => {
  return firebaseService.getUserId();
};
export type RequestInput = {
  url: string;
  params?: object;
  body?: object;
};

export const baseClient = {
  get: async <T>({
    url,
    params,
  }: Pick<RequestInput, "url" | "params">): Promise<T> => {
    return await axios
      .get(baseUrl + url, {
        headers: {
          Authorization: `Bearer ${await getToken()}`,
        },
        params,
      })
      .then((res) => {
        return res.data;
      });
  },
  put: async <T>({ url, params, body }: RequestInput): Promise<T> => {
    return await axios
      .put(baseUrl + url, body, {
        headers: {
          Authorization: `Bearer ${await getToken()}`,
        },
        params,
      })
      .then((res) => res.data);
  },
  post: async <T>({ url, params, body }: RequestInput): Promise<T> => {
    return await axios
      .post(baseUrl + url, body, {
        headers: {
          Authorization: `Bearer ${await getToken()}`,
        },
        params,
      })
      .then((res) => res.data);
  },
  delete: async <T>({ url, params }: RequestInput): Promise<T> => {
    return await axios
      .delete(baseUrl + url, {
        headers: {
          Authorization: `Bearer ${await getToken()}`,
        },
        params,
      })
      .then((res) => res.data);
  },
};
