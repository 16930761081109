import React, { forwardRef } from "react";
import { Modal } from "@mui/material";

const DSModal = forwardRef<HTMLDivElement, React.ComponentProps<typeof Modal>>(
  (props, ref) => {
    return <Modal ref={ref} {...props} />;
  },
);

export default DSModal;
