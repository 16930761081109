import { useState } from "react";
import { DSBaseTableProps } from "../components/DSBaseTable";

const useBaseTable = <T>({ data, columns, fetchMore }: DSBaseTableProps<T>) => {
  const defultRowsPerPage = 5;
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(defultRowsPerPage);

  const defaultWidth = 100 / columns.length;

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
    if (fetchMore && (newPage + 1) * rowsPerPage >= data.length) {
      fetchMore();
    }
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  const paginatedData = data.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage,
  );

  return {
    defaultWidth,
    paginatedData,
    page,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
  };
};
export default useBaseTable;
