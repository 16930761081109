import React from "react";
import { TablePagination } from "@mui/material";

const DSTablePagination = (
  props: React.ComponentProps<typeof TablePagination>,
) => {
  return (
    <TablePagination
      component={"div"}
      rowsPerPageOptions={[5, 10]}
      {...props}
    />
  );
};

export default DSTablePagination;
