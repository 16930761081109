import { Klydo_deprecated } from "Types";
import { colType } from "../Types";
import MsTable from "components/Widgets/Table";
import firebaseService from "firebase_service/firebaseService";
import { useEffect, useState } from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import ButtonLoader from "components/Widgets/ButtonLoader";
import { FaPen, FaTrash } from "react-icons/fa";
import { isVideo, percentToPresent, resizeCloudinary } from "../utils/util";
import { calcTimes } from "components/Widgets/TimeCalc";
import ReviewModal from "./ReviewModal";
import { NavLink } from "react-router-dom";

function Reviews() {
  const [data, setData] = useState<Array<Klydo_deprecated>>();
  const [klydo, setKlydo] = useState<Klydo_deprecated>();
  // const [selected, setSelected] = useState<Klydo[]>([])
  // const [bulkAction, setBulkAction] = useState("")
  useEffect(() => {
    const l = (reviews: Klydo_deprecated[]) => {
      setData([...reviews]);
    };
    firebaseService.listen<Klydo_deprecated>("reviews", l);
    return () => firebaseService.removeListener("reviews", l);
  }, []);

  const tableColumns: Array<colType> = [
    // {
    // 	noHeadline: true,
    // 	sort: false,
    // 	label: 'choose',
    // 	field: 'choose'
    // },
    {
      sort: true,
      label: "Name",
      field: "name",
    },
    {
      sort: true,
      label: "id",
      field: "idf",
    },
    {
      sort: true,
      label: "Date",
      field: "reviewDate",
    },
    {
      sort: true,
      label: "Author",
      field: "author",
    },
    {
      size: 60,
      sort: false,
      label: "Gif Ready",
      field: "zip",
    },
    {
      sort: false,
      label: "gif",
      field: "image",
    },
    {
      size: 32,
      noHeadline: true,
      sort: false,
      label: "Type",
      field: "type",
    },
    {
      size: 32,
      noHeadline: true,
      sort: false,
      label: "Confirm",
      field: "confirm",
    },
  ];

  const tableRows = (r: Klydo_deprecated, key: number) => {
    return {
      name: r.name,
      idf: <p title={r.id}>{r.idf}</p>,
      image:
        !r.loopUrl || isVideo(r.loopUrl) === "none" ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <p>File type not supported</p>
          </div>
        ) : (
          <div
            style={{
              borderRadius: "50%",
              width: 96,
              height: 96,
              position: "relative",
              overflow: "hidden",
            }}
          >
            <div
              style={{
                position: "absolute",
                top: r.crop?.top || 0 + "%",
                left: r.crop?.left || 0 + "%",
                width: `${r.crop?.zoom || 100}%`,
                aspectRatio: "1/1",
                display: "flex",
                justifyContent: "center",
              }}
            >
              {isVideo(r.loopUrl) === "image" ? (
                <img
                  loading="lazy"
                  alt="gif"
                  src={resizeCloudinary(r.loopUrl, 96)}
                  style={{ width: "auto", height: "100%", objectFit: "cover" }}
                />
              ) : (
                <video
                  muted={true}
                  src={resizeCloudinary(r.loopUrl, 96)}
                  loop
                  autoPlay
                  style={{ width: "auto", height: "100%", objectFit: "cover" }}
                />
              )}
            </div>
          </div>
        ),
      author: (
        <NavLink
          to="/admin/users"
          onClick={() => {
            localStorage.setItem("userssearch", r.author);
          }}
        >
          {r.author}
        </NavLink>
      ),
      reviewDate: r.review?.date?.toLocaleString("en-GB"),
      times: percentToPresent(calcTimes(r.times ?? [])),
      confirm: (
        <div
          className="fa-solid fa-check"
          title={
            r.review?.type === "delete"
              ? "Delete Klydo"
              : r.zip && r.review?.type === "edit"
                ? "Publish Klydo"
                : "Not ready"
          }
          style={{ padding: 5 }}
          onClick={(e) => {
            e.stopPropagation();
            if (r.zip || r.review?.type === "delete") setKlydo(r);
          }}
        ></div>
      ),
      type: r.review?.type === "delete" ? <FaTrash /> : <FaPen />,
      zip: (r.zip ?? false) + "",
      key: key,
    };
  };

  return (
    <>
      {data ? (
        <MsTable
          id="reviews"
          rowBuild={tableRows}
          search={(row: any, val: string) => {
            return (
              row.name.toLowerCase().includes(val) ||
              row.idf.includes(val) ||
              row.id.includes(val) ||
              row.author?.toLowerCase().includes(val)
            );
          }}
          data={data!}
          initSort={"reviewDate"}
          cols={tableColumns}
          title="Reviews"
          modal={ReviewModal}
        />
      ) : (
        <Spinner></Spinner>
      )}
      <Modal show={klydo !== undefined} onHide={() => setKlydo(undefined)}>
        <Modal.Header style={{ justifyContent: "right" }}>
          <i
            className="nc-icon nc-simple-remove"
            onClick={() => setKlydo(undefined)}
          ></i>
        </Modal.Header>
        <Modal.Body>
          <h4>
            Are you sure you want to{" "}
            {klydo?.review.type === "edit" ? "make it" : "remove it from"}{" "}
            public?
          </h4>
          <h5>{klydo?.name}</h5>
          <Button
            style={{ width: 180, margin: 10 }}
            onClick={() => setKlydo(undefined)}
          >
            No
          </Button>
          <ButtonLoader
            style={{ width: 180, margin: 10 }}
            title="Yes"
            onClick={() => {
              return new Promise<void>((v, x) => {
                firebaseService
                  .acceptReview(klydo!, klydo?.review.type === "delete")
                  .then(() => {
                    setKlydo(undefined);
                    v();
                  })
                  .catch(x);
              });
            }}
          />
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Reviews;
