import { useTheme } from "@mui/material";
import DSAvatar from "../../system-design/DSAvatar";

const PacksListAvatar = ({ packCover }: { packCover?: string }) => {
  const { spacing } = useTheme();
  return (
    <DSAvatar
      src={packCover}
      sx={{
        width: spacing(10),
        height: spacing(10),
        borderRadius: spacing(3),
      }}
    />
  );
};

export default PacksListAvatar;
