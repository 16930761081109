import { useTheme } from "@mui/material";
import { keyframes } from "@emotion/react";

const usePendulum = () => {
  const { zIndex } = useTheme();
  const swingAnimation = keyframes`
  0% { transform: rotate(-20deg); }
  50% { transform: rotate(20deg); }
  100% { transform: rotate(-20deg); }
`;

  const rodSwingAnimation = keyframes`
  0% { transform: rotate(-3deg); }
  50% { transform: rotate(3deg); }
  100% { transform: rotate(-3deg); }
`;
  return {
    zIndex,
    swingAnimation,
    rodSwingAnimation,
  };
};

export default usePendulum;
