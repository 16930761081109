import { useState } from "react";
import { Button } from "react-bootstrap";
import PacksReviews from "../../Packs/components/PacksReview";
import KlydosReviews from "../../Klydos/KlydoReviewV2/components/KlydosReviewsContainer";
const ReviewsSection = () => {
  const [selectedTab, setSelectedTab] = useState<
    "packs" | "klydos" | undefined
  >(undefined);
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        <Button
          active={selectedTab === "klydos"}
          onClick={() => setSelectedTab("packs")}
        >
          Packs
        </Button>
        <Button
          active={selectedTab === "packs"}
          onClick={() => setSelectedTab("klydos")}
        >
          Klydos
        </Button>
      </div>
      {selectedTab === "packs" && <PacksReviews />}
      {selectedTab === "klydos" && <KlydosReviews />}
    </>
  );
};
export default ReviewsSection;
