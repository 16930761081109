import useClockView from "./hooks/useClockView";

type ClockViewProps = {
  handHour: React.RefObject<HTMLDivElement>;
  handMin: React.RefObject<HTMLDivElement>;
  handHourS: React.RefObject<HTMLDivElement>;
  handMinS: React.RefObject<HTMLDivElement>;
  handsColor: string;
  dialsColor: string;
  isDialsOn: boolean;
};

const ClockView = ({
  handHour,
  handMin,
  handHourS,
  handMinS,
  handsColor,
  isDialsOn,
  dialsColor,
}: ClockViewProps) => {
  const { zIndex } = useClockView();

  return (
    <time
      dateTime="2023-06-30T04:48:07.668Z"
      style={{
        padding: "48%",
        position: "absolute",
      }}
    >
      {isDialsOn &&
        [
          {
            x: 72.3334,
            y: 8.130206022131384,
          },
          {
            x: 89.53659397786862,
            y: 25.333400000000005,
          },
          {
            x: 95.8334,
            y: 48.8334,
          },
          {
            x: 89.53659397786862,
            y: 72.3334,
          },
          {
            x: 72.3334,
            y: 89.5365939778686,
          },
          {
            x: 48.8334,
            y: 95.8334,
          },
          {
            x: 25.33340000000001,
            y: 89.53659397786862,
          },
          {
            x: 8.130206022131377,
            y: 72.3334,
          },
          {
            x: 1.8334,
            y: 48.833400000000005,
          },
          {
            x: 8.130206022131384,
            y: 25.333399999999997,
          },
          {
            x: 25.33339999999998,
            y: 8.130206022131398,
          },
          {
            x: 48.83339999999999,
            y: 1.8334,
          },
        ].map(({ x, y }, index) => {
          return (
            <div
              key={index}
              style={{
                top: `${y}%`,
                left: `${x}%`,
                position: "absolute",
                fontSize: "1.2em",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                zIndex: zIndex.clockDials,
                color: dialsColor,
                borderRadius: "50%",
                width: "4.333%",
                height: "4.333%",
                backgroundColor: dialsColor,
              }}
            ></div>
          );
        })}
      <div // hour shadow
        ref={handHourS}
        style={{
          position: "absolute",
          width: "100%",
          height: "100%",
          left: "0.4%",
          top: "1.2%",
          transformOrigin: "center",
          zIndex: zIndex.handHourS,
        }}
      >
        <div
          style={{
            position: "absolute",
            borderRadius: "10px",
            width: "4%",
            height: "35%",
            bottom: "46%",
            left: "48%",
            background:
              "radial-gradient(ellipse at center, #000000 0%, #00000000 100%)",
          }}
        />
      </div>
      <div // hour
        ref={handHour}
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          transformOrigin: "center",
          zIndex: zIndex.handHour,
        }}
      >
        <div
          style={{
            position: "absolute",
            borderRadius: "10px",
            width: "3%",
            height: "35%",
            bottom: "46%",
            left: "48%",
            backgroundColor: handsColor,
          }}
        />
      </div>
      <div // minute shadow
        ref={handMinS}
        style={{
          position: "absolute",
          width: "100%",
          height: "100%",
          left: "1.2%",
          top: "1.4%",
          transformOrigin: "center",
          zIndex: zIndex.handMinuteS,
        }}
      >
        <div
          style={{
            position: "absolute",
            borderRadius: "4px",
            width: "3%",
            height: "45%",
            bottom: "45%",
            left: "48.75%",
            background:
              "radial-gradient(ellipse at center, #000000 0%, #00000000 100%)",
          }}
        />
      </div>
      <div // minute
        ref={handMin}
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          zIndex: zIndex.handMinute,
          transformOrigin: "center",
        }}
      >
        <div
          style={{
            position: "absolute",
            borderRadius: "4px",
            width: "2%",
            height: "45%",
            bottom: "45%",
            left: "48.75%",
            backgroundColor: handsColor,
          }}
        />
      </div>
    </time>
  );
};

export default ClockView;
