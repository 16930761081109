import { useState } from "react";
import { ReviewFormStatus } from "../../Types";

const useReviewFormModal = (
  handleClose: () => void,
  handleSubmitReview: (message: string) => void,
  review: ReviewFormStatus,
) => {
  const [message, setMessage] = useState<string>(review?.message || "");

  const handleSubmit = () => {
    handleSubmitReview(message);
    handleClose();
  };

  return {
    message,
    setMessage,
    handleSubmit,
  };
};

export default useReviewFormModal;
