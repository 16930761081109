import { Device } from "../Types";
import firebaseService from "./firebaseService";
import firebase from "firebase/compat/app";

class FeatureFlagsService {
  private firebaseService = firebaseService;

  async getAllFeaturesFromGeneral() {
    const doc = await this.firebaseService.db
      .collection("general")
      .doc("features")
      .get();

    const featuresData = doc.data();
    return featuresData;
  }

  async updateFeatureToGeneralFeatures(
    featureName: string,
    featureValue: string,
  ) {
    if (!featureName || !featureValue) {
      throw new Error("Feature name and value are required.");
    }

    await this.firebaseService.db
      .collection("general")
      .doc("features")
      .update({
        [featureName]: featureValue,
      });
  }

  async removeFeatureFromGeneralFeatures(featureName: string) {
    if (!featureName) {
      throw new Error("Feature name is required to remove.");
    }

    const promises: Promise<void>[] = [
      this.removeFeatureFromAllMachine(featureName),
      this.firebaseService.db
        .collection("general")
        .doc("features")
        .update({
          [featureName]: firebase.firestore.FieldValue.delete(),
        }),
    ];
    await Promise.all(promises);
  }

  async getDevicesByFeatureFlag(flagName: string): Promise<Device[]> {
    if (!flagName) {
      throw new Error("Feature flag name is required.");
    }

    const snapshot = await this.firebaseService.db
      .collection("machines")
      .where(`features.value.${flagName}`, "in", [true, false])
      .get();

    const devices = snapshot.docs.map((doc) => doc.data());
    return devices as Device[];
  }

  async updateFeatureToMachine(
    deviceIdf: string,
    flagName: string,
    flagValue: boolean = true,
  ) {
    if (!deviceIdf || !flagName) {
      throw new Error("Device IDF and feature flag name are required.");
    }

    const querySnapshot = await this.firebaseService.db
      .collection("machines")
      .where("idf", "==", deviceIdf)
      .limit(1)
      .get();

    if (querySnapshot.empty) {
      throw new Error(`No machine found with IDF: ${deviceIdf}`);
    }

    const doc = querySnapshot.docs[0];
    await doc.ref.update({
      [`features.value.${flagName}`]: flagValue,
    });
  }

  async removeFeatureFromMachine(deviceIdf: string, flagName: string) {
    if (!deviceIdf || !flagName) {
      throw new Error("Device IDF and feature flag name are required.");
    }

    const querySnapshot = await this.firebaseService.db
      .collection("machines")
      .where("idf", "==", deviceIdf)
      .limit(1)
      .get();

    if (querySnapshot.empty) {
      throw new Error(`No machine found with IDF: ${deviceIdf}`);
    }

    const doc = querySnapshot.docs[0];
    await doc.ref.update({
      [`features.value.${flagName}`]: firebase.firestore.FieldValue.delete(),
    });
  }

  async removeFeatureFromAllMachine(flagName: string) {
    if (!flagName) {
      throw new Error("Feature flag name is required.");
    }

    const querySnapshot = await this.firebaseService.db
      .collection("machines")
      .get();

    if (querySnapshot.empty) {
      throw new Error("No machines found.");
    }

    querySnapshot.forEach(async (doc) => {
      await doc.ref.update({
        [`features.value.${flagName}`]: firebase.firestore.FieldValue.delete(),
      });
    });
  }

  async updateFeatureForAllMachine(flagName: string, value: boolean) {
    if (!flagName) {
      throw new Error("Feature flag name is required.");
    }

    const querySnapshot = await this.firebaseService.db
      .collection("machines")
      .get();

    if (querySnapshot.empty) {
      throw new Error("No machines found.");
    }

    querySnapshot.forEach(async (doc) => {
      await doc.ref.update({
        [`features.value.${flagName}`]: value,
      });
    });
  }
}

const featureFlagsService = new FeatureFlagsService();
export default featureFlagsService;
