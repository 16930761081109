import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from "@tanstack/react-query";
import adminApiClient from "../../../../api/admin";

const useDeleteUserMutation = (
  options: UseMutationOptions<void, Error, string, unknown> = {},
) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ["deleteUser"],
    mutationFn: async (userId: string) => {
      return adminApiClient.deleteUser(userId);
    },
    onSuccess: (data: void, variables: string, context: unknown) => {
      queryClient.invalidateQueries({
        queryKey: ["users"],
      });
      options?.onSuccess?.(data, variables, context);
    },
    ...options,
  });
};
export default useDeleteUserMutation;
