import { useCallback, useState } from "react";

import { DraftPack } from "../../../Types";
import { Column } from "../../system-design/Table/DSBaseTable/components/DSBaseTable";
import { debounce } from "lodash";
import usePacksReviewInfiniteQuery from "./usePacksReviewInfiniteQuery";

const usePacksReviewedList = () => {
  const [selectedPack, setSelectedPack] = useState<DraftPack | undefined>(
    undefined,
  );
  const [textSearch, setTextSearch] = useState<string>("");
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const columns: Column<DraftPack>[] = [
    { field: "name", label: "Name" },
    { field: "cover", label: "Cover" },
    { field: "creatorId", label: "Creator" },
    { field: "reviewStatus", label: "Status" },
    { field: "createdAt", label: "Created At" },
  ];

  const handleSelectRow = useCallback((pack: DraftPack) => {
    setSelectedPack(pack);
    setIsModalOpen(true);
  }, []);

  const handleTextSearch = useCallback(
    debounce((text) => setTextSearch(text), 500),
    [],
  );

  const handleCloseModal = useCallback(() => {
    setIsModalOpen(false);
    setSelectedPack(undefined);
  }, []);

  const { data, fetchNextPage, ...rest } =
    usePacksReviewInfiniteQuery(textSearch);

  return {
    data,
    fetchNextPage,
    ...rest,
    columns,
    selectedPack,
    handleSelectRow,
    textSearch,
    handleTextSearch,
    isModalOpen,
    handleCloseModal,
  };
};

export default usePacksReviewedList;
