import { Klydo_deprecated } from "../Types";
import { baseClient } from "./baseRequest";

export enum KlydoType {
  Draft = "DraftKlydo",
  Approved = "ApprovedKlydo",
}

export enum KlydoReviewStates {
  Approved = "approved",
  Pending = "pending",
  Rejected = "rejected",
  Draft = "draft",
}

export type KlydoReviewStatus = {
  state: KlydoReviewStates;
  message: string;
};

export type Theme = {
  backgroundColor: string;
  handsColor: string;
  pendulumColor: string;
  pendulumRodColor: string;
  dialsColor?: string;
};

export type BaseKlydo = {
  name: string;
  loopUrl: string;
  convertedLoopUrl?: string;
  theme: Theme;
  id: string;
  creator: string;
  public: boolean;
  dials: boolean;
  klydoSecureUrl?: string;
  createdAt: Date;
  updatedAt: Date;
  authorName: string;
  type: KlydoType;
};

export type ApprovedKlydo = BaseKlydo & {
  type: KlydoType.Approved;
  stats: {
    favorites: number;
    viewed: number;
    time: number;
  };
};

export type DraftKlydo = BaseKlydo & {
  type: KlydoType.Draft;
  reviewStatus: KlydoReviewStatus;
};

export type KlydoUnion = DraftKlydo | ApprovedKlydo;

export type AnalyticsRow = {
  clock: string;
  favorite: boolean;
  clock_time: number;
  display_time: number;
  auto_switch: boolean;
  collection_type: number;
};

export type KlydoSearchFilter = {
  pool?: boolean;
  unlisted?: boolean;
  excludeIds?: string[];
  notScheduled?: boolean;
  notPool?: boolean;
  inReview?: boolean;
};

export type KlydoApiClient = {
  updateKlydo: (id: string, klydo: Partial<Klydo_deprecated>) => Promise<void>;
  deleteKlydo: (id: string) => Promise<void>;
  reviewTextSearch: (
    query: string,
    limit: number,
    skip: number,
    parseData?: boolean,
    filters?: {
      pool?: boolean;
      unlisted?: boolean;
      inTasks?: boolean;
    },
    sort?: { [key: string]: number },
  ) => Promise<DraftKlydo[]>;

  approvedTextSearch: (
    query: string,
    limit: number,
    skip: number,
    parseData?: boolean,
    filters?: {
      pool?: boolean;
      unlisted?: boolean;
      inTasks?: boolean;
    },
    sort?: { [key: string]: number },
  ) => Promise<Klydo_deprecated[]>;

  getKlydosAnalytics: (
    klydoId: string[],
    deviceId: string,
  ) => Promise<AnalyticsRow[]>;
  getKlydoById: (klydoId: string) => Promise<Klydo_deprecated>;
  approveKlydo: (klydoId: string) => Promise<void>;
  updateDraftKlydo: (
    klydoId: string,
    klydo: Partial<DraftKlydo>,
  ) => Promise<void>;
};

const klydoApiClient: KlydoApiClient = {
  updateKlydo: async (id, klydo) => {
    return baseClient.put({
      url: `/admin/klydos/${id}`,
      body: klydo,
    });
  },
  deleteKlydo: async (id) => {
    return baseClient.delete({
      url: `/admin/klydos/${id}`,
    });
  },
  approvedTextSearch: async (
    query,
    limit,
    skip,
    includeMeteData,
    filters,
    sort,
  ) => {
    return baseClient.post<Klydo_deprecated[]>({
      url: `/admin/klydos/.search/approved?query=${query}`,
      body: {
        limit: limit,
        skip: skip,
        includeMeteData: includeMeteData,
        filters: filters,
        sort: sort,
      },
    });
  },
  reviewTextSearch: async (
    query,
    limit,
    skip,
    includeMeteData,
    filters,
    sort,
  ) => {
    return baseClient.post<DraftKlydo[]>({
      url: `/admin/klydos/.search/review?query=${query}`,
      body: {
        limit: limit,
        skip: skip,
        filters: filters,
        sort: sort,
      },
    });
  },

  getKlydosAnalytics: async (klydoId: string[], deviceId: string) => {
    return baseClient.post<AnalyticsRow[]>({
      url: `/klydos/get-klydos-analytics`,
      body: {
        klydoId,
        deviceId,
      },
    });
  },

  getKlydoById: async (klydoId: string) => {
    return baseClient.get<Klydo_deprecated>({
      url: `/klydos/${klydoId}`,
    });
  },

  approveKlydo: async (klydoId: string) => {
    return baseClient.put({
      url: `/admin/klydos/approve/${klydoId}`,
    });
  },
  updateDraftKlydo: async (klydoId: string, klydo: Partial<DraftKlydo>) => {
    return baseClient.put({
      url: `/admin/klydos/update-draft/${klydoId}`,
      body: { klydo },
    });
  },
};
export default klydoApiClient;
