import { useInfiniteQuery, useQueryClient } from "@tanstack/react-query";
import giftCategoriesClient from "../../../../api/giftCategories";

const useGiftOrdersQuery = () => {
  const queryClient = useQueryClient();
  const limit = 20;
  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, ...rest } =
    useInfiniteQuery({
      queryKey: ["giftOrders"],
      queryFn: async ({ pageParam }) => {
        const { skip = 0 } = pageParam || {};
        const res = await giftCategoriesClient.listGiftOrders({ skip, limit });

        res.forEach((order) => {
          queryClient.setQueryData(["giftOrder", order.orderId], order);
        });

        return res;
      },
      getNextPageParam: (lastPage, allPages) => {
        const currentLength = allPages.flat().length;
        return lastPage.length < limit ? undefined : { skip: currentLength };
      },
      initialPageParam: { skip: 0 },
    });

  const flatData = data?.pages.flat();

  return {
    data: flatData,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    ...rest,
  };
};

export default useGiftOrdersQuery;
