/*!

=========================================================
* Light Bootstrap Dashboard React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { useLocation, Route, Routes } from "react-router-dom";

import AdminNavbar from "../components/Navbars/AdminNavbar";
import Sidebar from "../components/Sidebar/Sidebar";

import sidebarImage from "../assets/img/cover.png";
import routes from "../routes";
import GiftCategories from "../views/Gifts/GiftCategories/components/GiftCategories";
function Admin() {
  const [image] = React.useState(sidebarImage);
  const [color] = React.useState("black");
  const [hasImage] = React.useState(true);
  const location = useLocation();
  const mainPanel = React.useRef(null);

  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    if (document.scrollingElement) document.scrollingElement.scrollTop = 0;
    if (
      window.innerWidth < 993 &&
      document.documentElement.className.indexOf("nav-open") !== -1
    ) {
      document.documentElement.classList.toggle("nav-open");
      const element = document.getElementById("bodyClick");
      if (element) element.parentNode!.removeChild(element);
    }
  }, [location]);

  return (
    <>
      <div className="wrapper">
        <Sidebar color={color} image={hasImage ? image : ""} routes={routes} />
        <div
          style={{ display: "flex", flexFlow: "column" }}
          className="main-panel"
          ref={mainPanel}
        >
          <AdminNavbar />
          <div
            className="content"
            style={{
              paddingBottom: 0,
              paddingRight: 0,
              display: "flex",
              flexFlow: "column",
              flex: "1 1 auto",
            }}
          >
            <Routes>
              {routes.map((i, k) => (
                <Route key={k} path={i.path} element={<i.element />} />
              ))}
              <Route path={"/gift-categories"} element={<GiftCategories />} />
            </Routes>
          </div>
        </div>
      </div>
    </>
  );
}

export default Admin;
