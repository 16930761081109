import { useMutation, useQueryClient } from "@tanstack/react-query";
import giftCategoriesClient from "../../../api/giftCategories";

const useDeleteClockGiftMutation = (clockId: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ["deviceGifts", clockId],
    mutationFn: () => giftCategoriesClient.deleteClockGift(clockId),
    onSettled: () =>
      queryClient.invalidateQueries({ queryKey: ["deviceGifts", clockId] }),
  });
};

export default useDeleteClockGiftMutation;
