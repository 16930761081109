import { Pack } from "../../../api/pack";
import DSCircularProgress from "../../system-design/DSCircularProgress";
import DSBox from "../../system-design/DSBox";
import usePacksList from "../hooks/usePublishedPacksList";
import DSTypography from "../../system-design/DSTypography";
import DSTextField from "../../system-design/DSTextField";
import { DSBaseTable, DSTableCell } from "../../system-design/Table";
import { PublishedPack } from "../../../Types";
import { Column } from "../../system-design/Table/DSBaseTable/components/DSBaseTable";
import PacksListAvatar from "./PacksListAvatar";

const PublishedPacks = () => {
  const {
    data: packs,
    fetchNextPage,
    isLoading,
    handleTextSearch,
    columns,
  } = usePacksList();

  const buildTableRows = (
    pack: PublishedPack,
    columns: Column<PublishedPack>[],
  ) => (
    <>
      {columns.map((col) => (
        <DSTableCell
          key={col.field as string}
          style={{
            width: col.size || `${100 / columns?.length}%`,
            whiteSpace: "nowrap",
          }}
        >
          {col.field === "cover" ? (
            <PacksListAvatar packCover={pack.cover} />
          ) : col.field === "reviewStatus" ? (
            pack.reviewStatus?.state || "N/A"
          ) : (
            String(pack[col.field])
          )}
        </DSTableCell>
      ))}
    </>
  );

  return (
    <>
      <DSBox mb={3} sx={{ marginRight: "20px" }}>
        <DSTypography variant="h6" mb={1}>
          Free Search
        </DSTypography>
        <DSTextField
          fullWidth
          variant="outlined"
          placeholder="Search Packs"
          onChange={(e) => {
            handleTextSearch(e.target.value);
          }}
        />
      </DSBox>
      {packs && !isLoading ? (
        <>
          <DSBaseTable
            columns={columns}
            rowBuilder={buildTableRows}
            fetchMore={fetchNextPage}
            data={packs}
          />
        </>
      ) : (
        !isLoading && (
          <DSTypography variant="body1" textAlign="center">
            <p>No Packs found</p>
          </DSTypography>
        )
      )}
      {isLoading && (
        <DSBox
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <DSCircularProgress />
        </DSBox>
      )}
    </>
  );
};

export default PublishedPacks;
