import React, { useState } from "react";
import { Button } from "react-bootstrap";
import GiftCategories from "../GiftCategories/components/GiftCategories";
import GiftOrders from "../GiftOrders/components/GiftOrders";
const Gifts = () => {
  const [layout, setLayout] = useState<"categories" | "orders" | undefined>(
    undefined,
  );
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        <Button
          active={layout === "categories"}
          onClick={() =>
            setLayout((prev) =>
              prev === "categories" ? undefined : "categories",
            )
          }
        >
          Categories
        </Button>
        <Button
          active={layout === "orders"}
          onClick={() =>
            setLayout((prev) => (prev === "orders" ? undefined : "orders"))
          }
        >
          Orders
        </Button>
      </div>
      {layout === "categories" && <GiftCategories />}
      {layout === "orders" && <GiftOrders />}
    </>
  );
};
export default Gifts;
