import { useQuery } from "@tanstack/react-query";
import giftCategoriesClient from "../../../api/giftCategories";

const useDeviceGiftQuery = (clockId: string) => {
  return useQuery({
    queryKey: ["deviceGifts", clockId],
    queryFn: () => giftCategoriesClient.getClockGift(clockId),
  });
};
export default useDeviceGiftQuery;
