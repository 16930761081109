import React from "react";
import { TableContainer } from "@mui/material";

const DSTableContainer = (
  props: React.ComponentProps<typeof TableContainer>,
) => {
  return <TableContainer {...props} />;
};

export default DSTableContainer;
