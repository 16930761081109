import React from "react";
import { CircularProgress } from "@mui/material";

const DSCircularProgress = (
  props: React.ComponentProps<typeof CircularProgress>,
) => {
  return <CircularProgress {...props} />;
};

export default DSCircularProgress;
