import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import adminApiClient from "../../../api/admin";
import firebaseService from "../../../firebase_service/firebaseService";
import useDeleteClockGiftMutation from "./useDeleteClockGiftMutation";
import useDeviceGiftQuery from "./useDeviceGiftQuery";
import { Klydo_deprecated } from "../../../Types";

const useDeviceModal = (id: string) => {
  const { data: machineData, isLoading } = useQuery({
    queryKey: ["machine"],
    queryFn: async () => {
      return adminApiClient.getClock(id);
    },
    refetchOnWindowFocus: false,
  });
  const [showKlydoViewingModal, setShowKlydoViewingModal] = useState(false);
  const { data: gift } = useDeviceGiftQuery(id);
  const { mutateAsync: deleteGift } = useDeleteClockGiftMutation(id);
  const [hideDev, setHideDev] = useState(
    machineData?.hideDev ? machineData.hideDev.value : true,
  );
  const [currentKlydo, setCurrentKlydo] = useState(
    machineData?.klydo?.id || "f90e5246-a4b2-4bf5-81ee-5f8d22241074",
  );
  const [timeOffset, setTimeOffset] = useState(
    machineData?.timeOffset ? machineData.timeOffset.value : 0,
  );
  const [updateDevApp, setUpdateDevApp] = useState(false);
  const [updateVersion, setUpdateVersion] = useState(
    firebaseService.general?.last,
  );
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [updateAppLoader, setUpdateAppLoader] = useState(false);
  const [schedualOn, setSchedualOn] = useState(
    machineData?.nightMode ? machineData.nightMode.value === "SCHEDULE" : false,
  );
  const [validated, setValidated] = useState(false);
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [warning, setWarning] = useState("");
  const [klydoAnalyticData, setKlydoAnalyticData] = useState<
    Array<{
      clockTime: Date;
      displayTime: number;
      klydo?: Klydo_deprecated;
      autoSwitch: boolean;
      favorite: boolean;
      collectionType: string;
    }>
  >();
  const [tableId] = useState("klydo-history");
  const [showRegisterModal, setShowRegisterModal] = useState(false);
  const [adminRegDetails, setAdminRegDetails] = useState<{
    country?: string;
    state?: string;
    city?: string;
    name?: string;
    email?: string;
  }>();

  function handleRef() {
    const ref = "https://klydo-editor.web.app/klydo/" + machineData?.klydo?.id;
    return ref as string;
  }

  return {
    data: machineData,
    isLoading,
    showKlydoViewingModal,
    setShowKlydoViewingModal,
    gift,
    deleteGift,
    hideDev,
    setHideDev,
    currentKlydo,
    setCurrentKlydo,
    timeOffset,
    setTimeOffset,
    updateDevApp,
    setUpdateDevApp,
    updateVersion,
    setUpdateVersion,
    showUpdateModal,
    setShowUpdateModal,
    updateAppLoader,
    setUpdateAppLoader,
    schedualOn,
    setSchedualOn,
    validated,
    setValidated,
    showRemoveModal,
    setShowRemoveModal,
    warning,
    setWarning,
    klydoAnalyticData,
    setKlydoAnalyticData,
    tableId,
    showRegisterModal,
    setShowRegisterModal,
    adminRegDetails,
    setAdminRegDetails,
    handleRef,
  };
};

export default useDeviceModal;
