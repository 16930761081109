import { baseClient } from "./baseRequest";
import { ClocksListResponse, Device } from "../Types";

export type VersionUpdate = {
  version: number;
  update: "public" | "beta" | "obsolete";
};

export type MonthlyRegisteredDevicesResponse = {
  firmware_version: string;
  month_registered: number;
  amount: number;
};

export type User = {
  name: string;
  email: string;
  id: string;
  photoUrl?: string;
  animated?: boolean;
  tag?: string;
  bio?: string;
  state?: string;
  description?: string;
  birthday?: Date;
  country?: string;
  socialMedia?: {
    link: string;
    network: string;
  }[];
};
export type KlydoAnalyzerConfig = {
  prompt: string;
  examples: string[];
};
export type AdminApiClient = {
  publishVersion: (versionUpdate: VersionUpdate) => Promise<void>;
  getAnalyzerConfig: () => Promise<KlydoAnalyzerConfig>;
  updateAnalyzerConfig: (input: KlydoAnalyzerConfig) => Promise<void>;
  createClockAuth: (clockSerial: string) => Promise<void>;
  updateUser: (
    userId: string,
    user: Partial<User> & { role?: string },
  ) => Promise<User>;
  deleteUser: (userId: string) => Promise<void>;
  createUser: (
    user: Partial<User> & { password: string; role: string },
  ) => Promise<User>;
  getClocks: (
    skip: number,
    offset: number,
    query: string,
    devClocks?: boolean,
  ) => Promise<ClocksListResponse[]>;
  getClock: (id: string) => Promise<Device>;
  getMonthlyRegisteredDevices: (
    monthLimit: number,
  ) => Promise<MonthlyRegisteredDevicesResponse[]>;
};

const adminApiClient: AdminApiClient = {
  updateUser: async (userId, user) => {
    return baseClient.put<User>({
      url: `/admin/users/${userId}`,
      body: user,
    });
  },
  getAnalyzerConfig: async () => {
    return baseClient.get<KlydoAnalyzerConfig>({
      url: `/admin/get-analyzer-config`,
    });
  },
  updateAnalyzerConfig: async (input) => {
    return baseClient.post<void>({
      url: `/admin/update-analyzer-config`,
      body: input,
    });
  },
  deleteUser: async (userId) => {
    return baseClient.delete<void>({
      url: `/admin/users/${userId}`,
    });
  },
  createClockAuth: async (clockSerial) => {
    return baseClient.post<void>({
      url: `/admin/clocks/create`,
      body: {
        serial: clockSerial,
      },
    });
  },
  createUser: async (user) =>
    baseClient.post<User>({
      url: "/admin/users/create",
      body: user,
    }),
  publishVersion: async (versionUpdate) => {
    return baseClient.post<void>({
      url: `/admin/publish-version`,
      body: versionUpdate,
    });
  },
  getClocks: async (
    skip: number,
    limit: number,
    query: string,
    devClocks?: boolean,
  ) => {
    if (!skip || !limit) {
      return baseClient.get<ClocksListResponse[]>({
        url: `/admin/clocks?search=${query}`,
        params: { skip, limit, dev: devClocks },
      });
    }
    return baseClient.get<ClocksListResponse[]>({
      url: `/admin/clocks?skip=${skip}&limit=${limit}&search=${query}`,
    });
  },
  getClock: async (id: string) => {
    return baseClient.get<Device>({
      url: `/admin/clocks/${id}`,
    });
  },

  getMonthlyRegisteredDevices: async (monthLimit: number) => {
    return baseClient.get<MonthlyRegisteredDevicesResponse[]>({
      url: `/admin/stats/get-monthly-registered-clocks?monthLimit=${monthLimit}`,
    });
  },
};
export default adminApiClient;
