import { useMutation, useQueryClient } from "@tanstack/react-query";
import klydoApiClient from "../../../../api/klydo";

const useUpdateKlydoMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ["approve-klydo"],
    mutationFn: ({ klydoId }: { klydoId: string }) => {
      return klydoApiClient.approveKlydo(klydoId);
    },

    onSettled: () =>
      queryClient.refetchQueries({
        queryKey: ["klydos"],
      }),
  });
};

export default useUpdateKlydoMutation;
