import { useState, useEffect } from "react";
import { Button, Form, Modal, Spinner } from "react-bootstrap";
import firebaseService from "../firebase_service/firebaseService";
import MsTable from "../components/Widgets/Table";
import ButtonLoader from "../components/Widgets/ButtonLoader";
import { Version, colType } from "../Types";
import { NavLink } from "react-router-dom";
import adminApiClient from "../api/admin";

const FORM_VERSIONS = [
  { key: "public", label: "Public version" },
  { key: "beta", label: "Beta version" },
  { key: "obsolete", label: "Obsolete version" },
];

type VersionState = {
  value: string;
  error: string;
};

type VersionStates = Record<string, VersionState>;

function Versions() {
  const [data, setData] = useState<Array<Version>>();
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [versionKeyToSubmit, setVersionKeyToSubmit] = useState<string | null>(
    null,
  );

  const [versionStates, setVersionStates] = useState<VersionStates>(
    FORM_VERSIONS.reduce<VersionStates>((accumulator, { key }) => {
      accumulator[key] = { value: "", error: "" };
      return accumulator;
    }, {}),
  );

  useEffect(() => {
    const listener = (versions: Array<Version>) => setData([...versions]);
    firebaseService.listen<Version>("version", listener);
    return () => {
      firebaseService.removeListener("version", listener);
    };
  }, []);

  useEffect(() => {
    setVersionStates((prev) =>
      FORM_VERSIONS.reduce<VersionStates>((accumulator, { key }) => {
        accumulator[key] = {
          ...prev[key],
          value: firebaseService.general?.[key] || "",
        };
        return accumulator;
      }, {}),
    );
  }, [firebaseService.general]);

  const handleInputChange = (key: string, value: string) => {
    const validated = data?.some((v) => v.version.toString() === value);

    setVersionStates((prev) => ({
      ...prev,
      [key]: {
        value,
        error: validated ? "" : "There is no such version",
      },
    }));
  };

  const handleCancel = () => {
    setShow(false);
    if (versionKeyToSubmit) {
      setVersionStates((prev) => ({
        ...prev,
        [versionKeyToSubmit]: {
          value: firebaseService.general?.[versionKeyToSubmit] || "",
          error: "",
        },
      }));
    }
    setVersionKeyToSubmit(null);
  };

  const publishVersion = async (key: string) => {
    const version = versionStates[key].value;

    await adminApiClient.publishVersion({
      version: parseFloat(version),
      update: key as "public" | "beta" | "obsolete",
    });

    setVersionStates((prev) => ({
      ...prev,
      [key]: {
        ...prev[key],
        value: version,
      },
    }));
  };

  const handleSubmit = async (key: string) => {
    setVersionKeyToSubmit(key);
    setShow(true);
  };

  const tableColumns: Array<colType> = [
    { sort: true, size: 75, label: "Version", field: "version" },
    { sort: true, label: "Date", field: "date" },
    { size: 700, sort: false, label: "Description", field: "description" },
    { sort: true, label: "Was Beta", field: "wasBeta" },
    { sort: true, label: "Was Public", field: "wasPublic" },
    { sort: true, label: "Devices", field: "devices" },
  ];

  const tableRows = (r: Version, key: string) => {
    return {
      version: <p className={r.devices === 0 ? "disabled" : ""}>{r.version}</p>,
      date: (
        <p className={r.devices === 0 ? "disabled" : ""}>
          {r.date.toLocaleString("en-GB")}
        </p>
      ),
      description: (
        <p className={r.devices === 0 ? "disabled" : ""}>{r.description}</p>
      ),
      wasPublic: <p>{r.wasPublic?.toString()}</p>,
      wasBeta: <p>{r.wasBeta?.toString()}</p>,
      devices: (
        <NavLink
          style={r.devices === 0 ? { cursor: "not-allowed" } : {}}
          to={r.devices > 0 ? "/admin/devices" : ""}
          onClick={() => {
            if (r.devices > 0) {
              localStorage.setItem("devicessearch", r.version.toString());
            }
          }}
        >
          <p className={r.devices === 0 ? "disabled" : ""}>{r.devices}</p>
        </NavLink>
      ),
      key: key,
    };
  };

  const renderForms = () => {
    return (
      <div>
        {FORM_VERSIONS.map(({ key, label }) => (
          <div style={{ display: "inline-block" }} key={key}>
            <Form noValidate={!versionStates[key].error}>
              {firebaseService.general?.[key] === versionStates[key].value && (
                <Form.Label style={{ color: "black", fontWeight: "bold" }}>
                  {label}:
                </Form.Label>
              )}
              <Form.Group controlId={`form-${key}`}>
                {versionStates[key].value && (
                  <Form.Control
                    value={versionStates[key].value || ""}
                    isInvalid={versionStates[key].error !== ""}
                    onChange={(e) => handleInputChange(key, e.target.value)}
                    type="number"
                    placeholder={`Enter ${label}`}
                    required
                  />
                )}
                <Form.Control.Feedback type="invalid">
                  {versionStates[key].error}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group>
                {firebaseService.general?.[key] &&
                  firebaseService.general?.[key] !== versionStates[key].value &&
                  !versionStates[key].error && (
                    <ButtonLoader
                      title={`Send to ${key}`}
                      onClick={() => handleSubmit(key)}
                    />
                  )}
              </Form.Group>
            </Form>
          </div>
        ))}
      </div>
    );
  };

  return (
    <>
      {data ? (
        <MsTable
          id={"versions"}
          buttons={renderForms()}
          rowBuild={tableRows}
          search={(row: Version, val: string) => {
            if (parseInt(val)) return row.version === parseInt(val);
            return row.description.toLowerCase().includes(val);
          }}
          data={data!}
          initSort={"version"}
          cols={tableColumns}
          title="Versions"
        />
      ) : (
        <Spinner />
      )}
      <Modal
        animation={false}
        show={show}
        onHide={() => setShow(false)}
        backdrop={!loading}
        keyboard
      >
        <Modal.Header>
          <Modal.Title>Are you sure?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {versionKeyToSubmit === "public"
            ? "This version will be available for all users."
            : versionKeyToSubmit === "beta"
              ? "This version will be sent to beta testers."
              : versionKeyToSubmit === "obsolete"
                ? "This version will be marked as obsolete."
                : ""}
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => handleCancel()}>Cancel</Button>
          <ButtonLoader
            title="OK"
            onClick={async () => {
              setLoading(true);
              await publishVersion(versionKeyToSubmit!).then(() => {
                setLoading(false);
                setShow(false);
              });
            }}
          />
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Versions;
