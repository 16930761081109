import { useQuery, useQueryClient } from "@tanstack/react-query";
import giftCategoriesClient from "../../../../api/giftCategories";
import { Klydo_deprecated } from "../../../../Types";

const useCategoriesQuery = () => {
  const queryClient = useQueryClient();
  return useQuery({
    queryKey: ["categories"],
    queryFn: () =>
      giftCategoriesClient.getAllCategories().then((res) => {
        const klydos = res
          .map((k) => k.klydos)
          .flat()
          .filter(Boolean) as Klydo_deprecated[];
        for (const klydo of klydos) {
          queryClient.setQueryData(["klydo", klydo.id], klydo);
        }
        return res;
      }),
  });
};
export default useCategoriesQuery;
