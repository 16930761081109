import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from "@tanstack/react-query";
import adminApiClient, { User } from "../../../../api/admin";

const useCreateUserMutation = (
  options: UseMutationOptions<
    User,
    Error,
    Partial<User> & {
      password: string;
      role: string;
    },
    unknown
  > = {},
) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ["createUser"],
    mutationFn: async (
      user: Partial<User> & { password: string; role: string },
    ) => {
      return adminApiClient.createUser(user);
    },
    onSuccess: (
      data: User,
      variables: Partial<User> & {
        password: string;
        role: string;
      },
      context: unknown,
    ) => {
      queryClient.invalidateQueries({
        queryKey: ["users"],
      });
      options?.onSuccess?.(data, variables, context);
    },
    ...options,
  });
};
export default useCreateUserMutation;
