import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.min.css";
// import "./assets/css/animate.min.css";
import "./assets/scss/light-bootstrap-dashboard-react.scss";
// import "./assets/css/demo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./assets/css/my.css";
import { createTheme, ThemeProvider } from "@mui/material";
import { KLYDO_BLACK, KLYDO_YELLOW } from "./consts/colors";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

declare module "@mui/material/styles" {
  interface ZIndex {
    pendulumRod: number;
    pendulumBall: number;
    clockDials: number;
    handMinute: number;
    handMinuteS: number;
    handHour: number;
    handHourS: number;
  }
}

const lightTheme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: KLYDO_YELLOW,
    },
    secondary: {
      main: KLYDO_BLACK,
    },
  },
});

root.render(
  <ThemeProvider theme={lightTheme}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </ThemeProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
