import {
  InfiniteData,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";
import packApiClient from "../../../api/pack";
import { DraftPack } from "../../../Types";

const useUpdatePackMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ["update-pack"],
    mutationFn: (pack: DraftPack) => {
      return packApiClient.updatePack(pack.id, { ...pack, klydos: undefined });
    },
    onMutate: async (pack: DraftPack) => {
      queryClient.setQueryData(["pack", pack.id], pack);

      const matchingQueries = queryClient
        .getQueryCache()
        .findAll({ queryKey: ["packs"] });

      matchingQueries.forEach(({ queryKey }) => {
        queryClient.setQueryData<InfiniteData<DraftPack[]>>(
          queryKey,
          (oldData) => {
            if (!oldData) return oldData;
            return {
              ...oldData,
              pages: oldData.pages.map((page) =>
                page.map((oldPack) =>
                  oldPack.id === pack.id ? { ...oldPack, ...pack } : oldPack,
                ),
              ),
            };
          },
        );
      });
    },
    onSettled: () =>
      queryClient.refetchQueries({
        queryKey: ["packs"],
      }),
  });
};

export default useUpdatePackMutation;
