import React, { useEffect } from "react";
import useAnalyzerConfigQuery from "../hooks/useAnalyzerConfigQuery";
import useAnalyzerConfigMutation from "../hooks/useAnalyzerConfigMutation";
import { Box, Button, TextField, Typography } from "@mui/material";
import { KlydoAnalyzerConfig } from "../../../api/admin";
const AnalyzerConfig = ({
  prompt: inputPrompt,
  examples: inputExamples,
}: KlydoAnalyzerConfig) => {
  const { mutateAsync: updateAnalyzerConfig } = useAnalyzerConfigMutation();
  const [prompt, setPrompt] = React.useState(inputPrompt);
  const [examples, setExamples] = React.useState(JSON.stringify(inputExamples));

  useEffect(() => {
    setPrompt(inputPrompt);
    setExamples(JSON.stringify(inputExamples));
  }, [inputPrompt, inputExamples]);
  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <Typography variant="h4">{"Analyzer Config"}</Typography>
      <TextField
        multiline
        value={prompt}
        label="Prompt"
        onChange={(e) => {
          setPrompt(e.target.value);
        }}
      />
      <TextField
        multiline
        value={examples}
        label="Examples (NO SPACES)"
        onChange={(e) => {
          setExamples(e.target.value);
        }}
      />
      <Button
        onClick={() =>
          updateAnalyzerConfig({
            prompt,
            examples: JSON.parse(examples),
          })
        }
      >
        Update
      </Button>
    </Box>
  );
};

const AnalyzerConfigWrapper = () => {
  const { data: analyzerConfig } = useAnalyzerConfigQuery();
  if (!analyzerConfig) {
    return <div>Loading...</div>;
  }
  return (
    <AnalyzerConfig
      prompt={analyzerConfig.prompt}
      examples={analyzerConfig.examples}
    />
  );
};

export default AnalyzerConfigWrapper;
