import { useInfiniteQuery, useQueryClient } from "@tanstack/react-query";
import klydoApiClient, { DraftKlydo, KlydoSearchFilter } from "../../api/klydo";
import { useEffect, useRef } from "react";

const useKlydosReviewQuery = (
  query: string,
  limit: number,
  filters?: KlydoSearchFilter,
  includeMeteData?: boolean,
  sortField?: string,
  sortDirection?: number,
) => {
  const queryClient = useQueryClient();
  const dataRef = useRef<DraftKlydo[]>();

  const { data, fetchNextPage, ...rest } = useInfiniteQuery<
    DraftKlydo[],
    Error
  >({
    queryKey: [
      "klydos",
      query,
      filters?.pool,
      filters?.unlisted,
      filters?.notScheduled,
      filters?.notPool,
      limit,
      sortField,
      sortDirection,
    ],
    queryFn: async ({ pageParam }) => {
      let sortOptions = undefined;
      if (sortField && sortDirection) {
        sortOptions = { [sortField]: sortDirection };
      }
      const { skip = 0 } = pageParam as { skip?: number };
      const res = await klydoApiClient.reviewTextSearch(
        query,
        limit,
        skip,
        includeMeteData || false,
        filters || {},
        sortOptions,
      );

      res.forEach((klydo) => {
        queryClient.setQueryData(["klydo", klydo.id], klydo);
      });

      return res;
    },
    getNextPageParam: (lastPage, allPages) => {
      const currentLength = allPages.flat().length;
      return currentLength < limit ? undefined : { skip: currentLength };
    },
    initialPageParam: { skip: 0 },
  });

  const flatData = data?.pages.flat();

  useEffect(() => {
    dataRef.current = flatData;
  }, [flatData]);

  return { data: flatData, dataRef, fetchNextPage, ...rest };
};

export default useKlydosReviewQuery;
