import { Command, Klydo_deprecated, ScheduledTask } from "../Types";
import { useEffect, useRef, useState } from "react";
import firebaseService from "../firebase_service/firebaseService";
import ScheduledTaskModal from "./ScheduledTaskModal";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "../assets/css/my.css";
import CommandModal from "./CommandModal";
import { Form, Modal, Spinner } from "react-bootstrap";
import { isVideo, resizeCloudinary } from "../utils/util";
import ButtonLoader from "components/Widgets/ButtonLoader";
import { FaPencilAlt } from "react-icons/fa";

const CommandCapsule = (props: {
  onClick?: () => void;
  task?: ScheduledTask;
  command?: Command;
  height: number;
  width: number;
  color: string;
  title?: string;
}) => {
  return (
    <div
      onClick={props.onClick}
      title={props.title}
      style={{
        cursor: "pointer",
        backgroundColor: props.color,
        height: props.height,
        fontSize: "12px",
        userSelect: "none",
        position: "relative",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-evenly",
        width: props.width,
        borderRadius: "5px",
      }}
    >
      {!props.command && props.title}
    </div>
  );
};

const DayTitleModal = ({
  handleClose,
  defaultValue,
  onClick,
}: {
  handleClose: (b: boolean) => void;
  defaultValue?: string;
  onClick: (title: string) => Promise<void>;
}) => {
  const [title, setTitle] = useState<string>("");
  return (
    <Modal
      className="modal-medium"
      show={true}
      onHide={() => handleClose(false)}
    >
      <Modal.Header
        style={{
          padding: 0,
          marginLeft: "10px",
          marginRight: "5px",
          marginBottom: "24px",
        }}
      >
        <h4 style={{ margin: 0, paddingTop: "16px", paddingLeft: "14px" }}>
          Add day title
        </h4>
        <i
          style={{ bottom: "7px", position: "relative" }}
          className="nc-icon nc-simple-remove"
          onClick={() => {
            handleClose(false);
          }}
        ></i>
      </Modal.Header>

      <Modal.Body>
        <Form
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
          }}
        >
          <Form.Control
            onKeyDown={(ev) => {
              ev.stopPropagation();
              if (ev.key !== "Enter") return;
              ev.preventDefault();
              onClick(title);
            }}
            onClick={(e) => e.stopPropagation()}
            onChange={(e) => {
              setTitle(e.target.value);
            }}
            defaultValue={defaultValue || ""}
            placeholder={defaultValue ? "" : "Enter day title..."}
          />
          <ButtonLoader
            style={{ marginTop: "4px", width: "30%" }}
            title="Save"
            onClick={() => onClick(title)}
          />
        </Form>
      </Modal.Body>
    </Modal>
  );
};

function ScheduledTasks() {
  const [tasks, setTasks] = useState<Array<ScheduledTask>>();
  const localizer = momentLocalizer(moment);
  const [showScheduledTaskModal, setShowScheduledTaskModal] = useState(false);
  const [showCommandModal, setShowCommandModal] = useState(false);
  const [selectedDate, setSelectedDate] = useState<Date | false>();
  const [selectedTask, setSelectedTask] = useState<ScheduledTask>();
  const [selectedCommand, setSelectedCommand] = useState<Command>();
  const [size, setSize] = useState<{ width: number; height: number }>();
  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();

    const l = (scheduledPool: Array<ScheduledTask>) => {
      setTasks([...scheduledPool]);
    };
    firebaseService.listen<ScheduledTask>("scheduledpool", l);

    return () => {
      window.removeEventListener("resize", handleResize);
      firebaseService.removeListener("scheduledpool", l);
    };
  }, []);

  function handleResize() {
    if (ref.current != null) {
      const xy = ref.current.getBoundingClientRect();
      let width = window.innerWidth - xy.left;
      const height = window.innerHeight - xy.top - 1;
      setSize({ width: width, height: height });
    }
  }

  const featuredTimesTitle = (featuredTimes: any) => {
    if (!featuredTimes) return "Featured";
    let str = "";
    if (featuredTimes.start) str += featuredTimes.start.toLocaleString() + "-";
    if (featuredTimes.end) str += featuredTimes.end.toLocaleString();
    return str;
  };

  const EventComponent = ({
    task,
    parentHeight,
  }: {
    task: ScheduledTask;
    parentHeight: number;
  }) => {
    const featured =
      (task.add as Klydo_deprecated[]).filter(
        (k: Klydo_deprecated) => k.featured,
      ).length > 0
        ? (task.add as Klydo_deprecated[]).filter(
            (k: Klydo_deprecated) => k.featured,
          )
        : undefined;
    return (
      <div
        style={{
          display: "flex",
          height: `${parentHeight}px`,
          flexDirection: "row-reverse",
          padding: 0,
          justifyContent: "space-between",
          overflowX: "hidden",
        }}
      >
        <div
          style={{
            display: "flex",
            flexFlow: "column wrap-reverse",
            alignItems: "center",
            maxHeight: `${parentHeight}px`,
          }}
        >
          {task.remove.length > 0 && (
            <CommandCapsule
              title={`-${task.remove.length}`}
              onClick={() => {
                setSelectedTask(task);
                setShowScheduledTaskModal(true);
              }}
              height={20}
              width={20}
              color="red"
            ></CommandCapsule>
          )}
          {task.add.length > 0 &&
            task?.add.slice(0, 4).map((klydo) => (
              <div
                onClick={() => {
                  setSelectedTask(task);
                  setShowScheduledTaskModal(true);
                }}
                title={klydo.idf}
                style={{
                  zIndex: 10,
                  cursor: "pointer",
                  borderRadius: "50%",
                  width: 48,
                  height: 48,
                  overflow: "hidden",
                }}
              >
                {typeof klydo !== "string" ? (
                  <div
                    style={{
                      width: `${klydo.crop?.zoom || 100}%`,
                      aspectRatio: "1/1",
                    }}
                  >
                    {isVideo(klydo.loopUrl) === "image" ? (
                      <img
                        loading="lazy"
                        alt="gif"
                        src={resizeCloudinary(klydo.loopUrl, 48)}
                        style={{
                          width: "auto",
                          height: "100%",
                          objectFit: "cover",
                        }}
                      />
                    ) : (
                      <video
                        muted={true}
                        src={resizeCloudinary(klydo.loopUrl, 48)}
                        loop
                        autoPlay
                        style={{
                          width: "auto",
                          height: "100%",
                          objectFit: "cover",
                        }}
                      />
                    )}
                  </div>
                ) : (
                  <Spinner style={{ width: 24, height: 24 }}></Spinner>
                )}
              </div>
            ))}
        </div>

        {featured && (
          <CommandCapsule
            height={20}
            width={20}
            color="blue"
            title={featuredTimesTitle(featured[0].featuredTimes)}
          ></CommandCapsule>
        )}
        <div
          style={{
            display: "flex",
            flexFlow: "column wrap",
            maxHeight: `${parentHeight}px`,
          }}
        >
          {task.commands.length > 0 &&
            task.commands
              .filter((cmd) => cmd.name !== "syncPool")
              .map((cmd) => {
                const hours = cmd.local
                  ? cmd.time.getHours().toString().padStart(2, "0")
                  : cmd.time.getUTCHours().toString().padStart(2, "0");
                const minutes = cmd.local
                  ? cmd.time.getMinutes().toString().padStart(2, "0")
                  : cmd.time.getUTCMinutes().toString().padStart(2, "0");
                let formattedTime = `${hours}:${minutes} ${cmd.local ? "Local" : "UTC"}`;
                return (
                  <div
                    style={{
                      marginLeft: "2px",
                      marginTop: "2px",
                      maxHeight: 20,
                    }}
                  >
                    <CommandCapsule
                      onClick={() => {
                        setSelectedCommand(cmd);
                        setShowCommandModal(true);
                      }}
                      command={cmd}
                      height={20}
                      width={20}
                      color={
                        cmd.name === "playKlydo"
                          ? "#e7abc9"
                          : cmd.name === "deleteKlydos"
                            ? "#6b3884"
                            : "#864408"
                      }
                      title={cmd.name + " " + formattedTime}
                    ></CommandCapsule>
                  </div>
                );
              })}
        </div>
      </div>
    );
  };

  const CustomDay = ({ date }: { date: Date }) => {
    const [showEdit, setShowEdit] = useState<boolean>(false);
    const dayNumber = date.getDate();
    const today = new Date();
    const parentRef = useRef<HTMLDivElement | null>(null);
    const [parentHeight, setParentHeight] = useState<number>();
    const [now] = useState<boolean>(
      date.getDate() === today.getDate() &&
        date.getMonth() === today.getMonth() &&
        date.getFullYear() === today.getFullYear()
        ? true
        : false,
    );
    const task = tasks!.find(
      (task) => task.date.toDateString() === date.toDateString(),
    );

    useEffect(() => {
      if (parentRef.current) {
        setParentHeight(parentRef.current.offsetHeight);
      }
    }, [parentRef.current?.offsetHeight]);

    return (
      <div
        ref={parentRef}
        style={{
          position: "relative",
          display: "flex",
          flexFlow: "column",
          flex: "1 1 auto",
          width: "100%",
        }}
        onMouseOver={() => setShowEdit(true)}
        onMouseOut={() => setShowEdit(false)}
      >
        <span
          style={{
            color: `${now ? "#0000007a" : "#0000001a"} `,
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: "50px",
            zIndex: 11,
            pointerEvents: "none",
          }}
        >
          {dayNumber}
        </span>
        <div
          style={{
            display: "flex",
            flexFlow: "column",
            height: `${parentHeight}px`,
          }}
        >
          <div style={{ display: "flex", justifyContent: "center" }}>
            {showEdit && (
              <FaPencilAlt
                title="Edit day title"
                style={{
                  color: "black",
                  position: "absolute",
                  bottom: "85%",
                  left: "1%",
                  cursor: "pointer",
                  fontSize: "12px",
                  zIndex: 11,
                }}
                onClick={() => {
                  setSelectedDate(date);
                  setSelectedTask(task);
                }}
              />
            )}
            {task?.tag && (
              <p
                title={task.tag}
                style={{
                  margin: 0,
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  fontSize: "12px",
                  color: "black",
                  marginRight: "4px",
                  marginLeft: "4px",
                  overflowX: "hidden",
                }}
              >
                {task.tag}
              </p>
            )}
          </div>
          {task && parentHeight && (
            <EventComponent
              parentHeight={parentHeight}
              task={task}
            ></EventComponent>
          )}
        </div>
      </div>
    );
  };
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row-reverse",
          color: "white",
          alignItems: "center",
        }}
      >
        <div
          style={{
            margin: 0,
            fontSize: "14px",
            width: 100,
            height: "fit-content",
            display: "flex",
            flexDirection: "column",
            borderRadius: "20%",
            marginRight: "20px",
            color: "black",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "90%",
              marginBottom: "4px",
            }}
          ></div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "90%",
              marginBottom: "4px",
            }}
          >
            <p style={{ margin: 0, fontSize: "14px" }}>Remove</p>
            <CommandCapsule height={15} width={15} color="red"></CommandCapsule>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "90%",
              marginBottom: "4px",
            }}
          >
            <p style={{ margin: 0, fontSize: "14px" }}>Featured</p>
            <CommandCapsule
              height={15}
              width={15}
              color="blue"
            ></CommandCapsule>
          </div>
        </div>
        <div
          style={{
            margin: 0,
            fontSize: "14px",
            width: 120,
            height: "fit-content",
            display: "flex",
            flexDirection: "column",
            borderRadius: "20%",
            marginRight: "25px",
            color: "black",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "90%",
              marginBottom: "4px",
            }}
          >
            <p style={{ margin: 0, fontSize: "14px" }}>Play klydo</p>
            <CommandCapsule
              height={15}
              width={15}
              color="#e7abc9"
            ></CommandCapsule>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "90%",
              marginBottom: "4px",
            }}
          >
            <p style={{ margin: 0, fontSize: "14px" }}>Delete klydo</p>
            <CommandCapsule
              height={15}
              width={15}
              color="#6b3884"
            ></CommandCapsule>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "90%",
              marginBottom: "4px",
            }}
          >
            <p style={{ margin: 0, fontSize: "14px" }}>Update app</p>
            <CommandCapsule
              height={15}
              width={15}
              color="#864408"
            ></CommandCapsule>
          </div>
        </div>
      </div>
      <div ref={ref}>
        {tasks ? (
          <Calendar
            style={{ width: size?.width + "px", height: size?.height + "px" }}
            localizer={localizer}
            allDayMaxRows={5}
            views={["month"]}
            startAccessor="start"
            endAccessor="end"
            components={{
              month: {
                dateHeader: ({ label, date }) => <CustomDay date={date} />,
              },
            }}
            dayPropGetter={() => {
              return {
                style: {
                  borderStyle: "solid",
                  borderWidth: "1px",
                  borderColor: "#ccc",
                  overflowY: "auto",
                },
              };
            }}
          />
        ) : (
          <Spinner></Spinner>
        )}
      </div>
      {showScheduledTaskModal && (
        <ScheduledTaskModal
          open={true}
          handleClose={(close: boolean) => {
            setShowScheduledTaskModal(close);
          }}
          task={selectedTask!}
        />
      )}
      {showCommandModal && (
        <CommandModal
          open={true}
          handleClose={(close: boolean) => {
            setShowCommandModal(close);
          }}
          command={selectedCommand!}
        />
      )}
      {selectedDate && (
        <DayTitleModal
          defaultValue={selectedTask?.tag}
          handleClose={() => setSelectedDate(false)}
          onClick={async (title) => {
            return new Promise<void>((v, x) => {
              firebaseService
                .updateScheduledTag(selectedDate, title)
                .then(() => {
                  setSelectedDate(false);
                  setSelectedTask(undefined);
                  v();
                })
                .catch(x);
            });
          }}
        ></DayTitleModal>
      )}
    </>
  );
}
export default ScheduledTasks;
