import { useState, useEffect } from "react";
import { Modal, Button, Form, Table } from "react-bootstrap";
import featureFlagsService from "../firebase_service/featureFlagsService";
import { Device } from "../Types";
import { Feature } from "./FeatureFlags";

export type FeatureModalProps = {
  setShowModal: (show: boolean) => void;
  row: Feature;
  data: Feature[];
};

export type DeviceRow = {
  idf: string;
  currentIdf: string;
  image: JSX.Element | string;
  delete: JSX.Element;
};

function FeatureModal({ setShowModal, row }: FeatureModalProps) {
  const feature = row;
  const [devices, setDevices] = useState<Device[]>([]);
  const [deviceId, setDeviceId] = useState("");
  const [showDelete, setShowDelete] = useState<boolean>(false);
  const [deviceToDelete, setDeviceToDelete] = useState<Device>();
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [featureValue, setFeatureValue] = useState<boolean>(
    feature.value == "on" ? true : false,
  );

  useEffect(() => {
    async function fetchDevices() {
      setIsLoading(true);
      const devicesWithFeature: Device[] =
        await featureFlagsService.getDevicesByFeatureFlag(feature?.name);
      setDevices(devicesWithFeature);
      setIsLoading(false);
    }
    fetchDevices();
  }, [feature]);

  const handleChange = async () => {
    setIsLoading(true);
    await featureFlagsService
      .getDevicesByFeatureFlag(feature.name)
      .then((devicesWithFeature) => {
        setDevices(devicesWithFeature);
        setIsLoading(false);
      });
  };

  const addFeatureToDevice = async () => {
    if (deviceId) {
      await featureFlagsService
        .updateFeatureToMachine(deviceId, feature.name)
        .then(() => {
          setDeviceId("");
          handleChange();
        });
    }
  };

  const RemoveFeatureFromAllDevices = async () => {
    setIsUpdating(true);
    await featureFlagsService
      .removeFeatureFromAllMachine(feature.name)
      .then(() => {
        handleChange();
        setIsUpdating(false);
      });
  };

  const updateFeaturValueToAllDevices = async (value: boolean) => {
    setIsUpdating(true);

    try {
      const promises: Promise<void>[] = [
        featureFlagsService.updateFeatureToGeneralFeatures(
          feature.name,
          value ? "on" : "off",
        ),
        featureFlagsService.updateFeatureForAllMachine(feature.name, value),
        handleChange(),
      ];

      await Promise.all(promises);
    } catch (error) {
      console.error("Error updating feature value for all devices:", error);
    } finally {
      setIsUpdating(false);
    }
  };

  const updateFeatureValue = async (
    updatedDeviceIdf: string,
    value: boolean,
  ) => {
    if (updatedDeviceIdf) {
      await featureFlagsService
        .updateFeatureToMachine(updatedDeviceIdf, feature.name, value)
        .then(() => {
          handleChange();
        });
    }
  };

  const removeFeatureFromDevice = async (selectedDeviceIdf: string) => {
    await featureFlagsService
      .removeFeatureFromMachine(selectedDeviceIdf, feature.name)
      .then(() => {
        handleChange();
      });
  };

  const DeleteFeatureModal = () => {
    return (
      <>
        {deviceToDelete && (
          <Modal onHide={() => setShowDelete(false)} show={showDelete}>
            <Modal.Header closeButton>
              <Modal.Title>Confirm Deletion</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Are you sure you want to delete the feature flag?
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setShowDelete(false)}>
                Cancel
              </Button>
              <Button
                variant="danger"
                onClick={() => {
                  removeFeatureFromDevice(deviceToDelete.idf);
                  setShowDelete(false);
                }}
              >
                Delete
              </Button>
            </Modal.Footer>
          </Modal>
        )}
      </>
    );
  };

  return (
    <>
      <Modal.Header
        style={{
          padding: 0,
          marginLeft: "10px",
          marginRight: "5px",
          marginBottom: "24px",
        }}
      >
        <h4 style={{ margin: 0, paddingTop: "16px", paddingLeft: "14px" }}>
          Manage Devices for Feature: {feature.name}
        </h4>
        <i
          style={{ bottom: "7px", position: "relative" }}
          className="nc-icon nc-simple-remove"
          onClick={() => {
            setShowModal(false);
          }}
        ></i>
      </Modal.Header>
      <Modal.Body>
        {featureValue ? (
          <Button
            variant="dark"
            onClick={() => {
              updateFeaturValueToAllDevices(false);
              setFeatureValue(false);
            }}
            disabled={isUpdating || isLoading}
          >
            Turn Off All Devices
          </Button>
        ) : (
          <Button
            variant="success"
            onClick={() => {
              updateFeaturValueToAllDevices(true);
              setFeatureValue(true);
            }}
            disabled={isUpdating || isLoading}
          >
            Turn On All Devices
          </Button>
        )}
        <Button
          variant="danger"
          onClick={() => RemoveFeatureFromAllDevices()}
          disabled={isUpdating || isLoading}
        >
          Remove Fetaure From All Devices
        </Button>
        <Form>
          <Form.Group>
            <Form.Label>Device IDF</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Device IDF"
              value={deviceId}
              onChange={(e) => setDeviceId(e.target.value)}
            />
          </Form.Group>
          <Button
            onClick={addFeatureToDevice}
            disabled={isUpdating || isLoading}
            variant="primary"
          >
            Add Feature to Device
          </Button>
        </Form>
        <hr />
        <div style={{ maxHeight: "400px", overflowY: "auto" }}>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Device IDF</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {devices.length == 0 || isUpdating || isLoading ? (
                <tr>
                  <td colSpan={2}>
                    {isLoading ? "Loading" : "No devices available"}
                  </td>
                </tr>
              ) : (
                devices.map((device) => (
                  <tr key={device.idf}>
                    <td>{device.idf}</td>
                    <td>
                      <Button
                        variant="danger"
                        onClick={() => {
                          setDeviceToDelete(device);
                          setShowDelete(true);
                        }}
                      >
                        Remove Feature
                      </Button>
                      {device.features?.value[feature.name] ? (
                        <Button
                          variant="dark"
                          onClick={() => updateFeatureValue(device.idf, false)}
                        >
                          Turn Off
                        </Button>
                      ) : (
                        <Button
                          variant="success"
                          onClick={() => updateFeatureValue(device.idf, true)}
                        >
                          Turn On
                        </Button>
                      )}
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </Table>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setShowModal(false)}>
          Close
        </Button>
      </Modal.Footer>
      {showDelete && <DeleteFeatureModal />}
    </>
  );
}

export default FeatureModal;
