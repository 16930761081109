import { useQuery } from "@tanstack/react-query";
import giftCategoriesClient from "../../../../api/giftCategories";

const useOrderQuery = (orderId: string) => {
  return useQuery({
    queryKey: ["order", orderId],
    queryFn: async () => giftCategoriesClient.getOrderInfo(orderId),
    enabled: !!orderId,
  });
};
export default useOrderQuery;
