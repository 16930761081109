import { Button, Form, InputGroup, Modal } from "react-bootstrap";
import { useWebSocket } from "../components/hooks/useWebSocket";
import { useEffect, useState } from "react";
import ButtonLoader from "../components/Widgets/ButtonLoader";

interface LogcatStreamModalProps {
  deviceId: string;
  onClose: () => void;
}

const FilterLog = ({ onFilter }: { onFilter: (text: string) => void }) => {
  const [text, setText] = useState<string>();
  return (
    <Form noValidate style={{ display: "flex", flexDirection: "column" }}>
      <Form.Group
        controlId="formBasicPassword"
        style={{ display: "flex", justifyContent: "flex-end" }}
      >
        <InputGroup style={{ marginRight: "5px" }}>
          <Form.Control
            value={text}
            placeholder="Enter text to filter"
            required
            onChange={(e) => {
              setText(e.target.value);
            }}
            type="text"
          ></Form.Control>
        </InputGroup>

        <ButtonLoader
          title="filter"
          disabled={!text}
          style={{ color: "#0d6efd", fontSize: "12px", width: "50%" }}
          onClick={async () => {
            onFilter(text!);
            setText("");
          }}
        ></ButtonLoader>
      </Form.Group>
    </Form>
  );
};

const LogcatStreamModal: React.FC<LogcatStreamModalProps> = ({
  deviceId,
  onClose,
}) => {
  const uri = "wss://clock-socket-917257785826.us-central1.run.app/";
  const {
    start,
    stop,
    sendCommand,
    isConnected,
    isReady,
    loadingMessage,
    logElement,
    clearLog,
  } = useWebSocket({ uri, devId: deviceId });

  useEffect(() => {
    start();
  }, []);

  const handleClear = () => {
    sendCommand("clear");
    clearLog();
  };

  const handleRead = () => {
    sendCommand("start");
  };

  const handleStop = () => {
    stop();
    onClose();
  };

  return (
    <Modal show onHide={onClose} size="xl">
      <Modal.Header closeButton>
        <Modal.Title>Logcat Stream</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div style={{ textAlign: "center", marginBottom: "10px" }}>
          <Button onClick={handleRead} disabled={!isReady}>
            Read
          </Button>
          <Button onClick={() => sendCommand("pause")} disabled={!logElement}>
            Pause
          </Button>
          <Button onClick={handleClear} disabled={!logElement}>
            Clear
          </Button>
          <Button onClick={handleStop} disabled={!isConnected}>
            Stop
          </Button>
          <FilterLog onFilter={(text) => sendCommand(text)} />
        </div>

        <div
          id="log"
          ref={logElement}
          style={{
            overflowY: "auto",
            padding: "10px",
            height: "60vh",
            backgroundColor: "#1e1e1e",
            color: "#00ff00",
            fontFamily: "monospace",
          }}
        >
          {loadingMessage ? <p>{loadingMessage}</p> : null}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default LogcatStreamModal;
