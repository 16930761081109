import { SpecialPack } from "../Types";

export const resizeCloudinary = (
  url: string | undefined,
  size: 200 | 150 | 96 | 48,
) => {
  if (!url) return "";
  if (url!.includes("image/upload") || url.includes("video/upload")) {
    return url.replace("/upload/", "/upload/c_scale,w_" + size + "/");
  }
  return url.replace("/klydoclock/", "/klydoclock/c_scale,w_" + size + "/");
};

export const myColor = "#B0BEC5";
const TIME_FOR_ONLINE_CHECK = 1800000;

export const statesList = [
  { label: "Alabama", value: "AL" },
  { label: "Alaska", value: "AK" },
  { label: "Arizona", value: "AZ" },
  { label: "Arkansas", value: "AR" },
  { label: "California", value: "CA" },
  { label: "Colorado", value: "CO" },
  { label: "Connecticut", value: "CT" },
  { label: "Delaware", value: "DE" },
  { label: "Florida", value: "FL" },
  { label: "Georgia", value: "GA" },
  { label: "Hawaii", value: "HI" },
  { label: "Idaho", value: "ID" },
  { label: "Illinois", value: "IL" },
  { label: "Indiana", value: "IN" },
  { label: "Iowa", value: "IA" },
  { label: "Kansas", value: "KS" },
  { label: "Kentucky", value: "KY" },
  { label: "Louisiana", value: "LA" },
  { label: "Maine", value: "ME" },
  { label: "Maryland", value: "MD" },
  { label: "Massachusetts", value: "MA" },
  { label: "Michigan", value: "MI" },
  { label: "Minnesota", value: "MN" },
  { label: "Mississippi", value: "MS" },
  { label: "Missouri", value: "MO" },
  { label: "Montana", value: "MT" },
  { label: "Nebraska", value: "NE" },
  { label: "Nevada", value: "NV" },
  { label: "New Hampshire", value: "NH" },
  { label: "New Jersey", value: "NJ" },
  { label: "New Mexico", value: "NM" },
  { label: "New York", value: "NY" },
  { label: "North Carolina", value: "NC" },
  { label: "North Dakota", value: "ND" },
  { label: "Ohio", value: "OH" },
  { label: "Oklahoma", value: "OK" },
  { label: "Oregon", value: "OR" },
  { label: "Pennsylvania", value: "PA" },
  { label: "Rhode Island", value: "RI" },
  { label: "South Carolina", value: "SC" },
  { label: "South Dakota", value: "SD" },
  { label: "Tennessee", value: "TN" },
  { label: "Texas", value: "TX" },
  { label: "Utah", value: "UT" },
  { label: "Vermont", value: "VT" },
  { label: "Virginia", value: "VA" },
  { label: "Washington", value: "WA" },
  { label: "West Virginia", value: "WV" },
  { label: "Wisconsin", value: "WI" },
  { label: "Wyoming", value: "WY" },
];

export const valueLabelState = (val: string): string => {
  const st = statesList.find((s) => s.value === val);
  if (st) return st.label;
  else return "not found";
};
export const labelValueState = (lbl: string): string => {
  const st = statesList.find((s) => s.label === lbl);
  if (st) return st.value;
  else return "not found";
};
export const percentToPresent = (num: number): string => {
  return (
    (num >= 10
      ? Math.round(num)
      : num > 2
        ? Math.round(num * 10) / 10
        : Math.round(num * 100) / 100) + "%"
  );
};
export enum weekEnum {
  SUNDAY,
  MONDAY,
  TUESDAY,
  WENDNESDAY,
  THURSDAY,
  FRIDAY,
  SATURDAY,
}
export enum ModalActions {
  DELETE = "deleteKlydos",
  PREVIEW = "playKlydo",
  UPDATE = "updateApp",
  UPDATE_REMOTE = "updateRemote",
  ONBOARDING = "onBoarding",
  SYNC = "syncPool",
  HIDE = "hide",
  SYNC_LOGS = "syncLogs",
  UPDATE_LOG_CONFIG = "updateLogConfig",
  LOGCAT = "logcat",
}

export const socialMediaList = [
  "instagram",
  "linktree",
  "twitter",
  "facebook",
  "tiktok",
  "behance",
  "dribbble",
  "youtube",
  "patreon",
  "tumblr",
  "deviantart",
  "artstation",
  "wikipedia",
  "imdb",
  "vimeo",
  "pinterest",
  "opensea",
  "rarible",
  "superrare",
  "foundation",
  "nifty",
];
type ViewType = "video" | "image" | "none";
export const isVideo = (url: string): ViewType => {
  if (!url) return "none";
  const imgTypes = [
    "jpg",
    "gif",
    "avif",
    "apng",
    "png",
    "ico",
    "cur",
    "jpeg",
    "jfif",
    "pjpeg",
    "pjp",
    "tif",
    "webp",
    "bmp",
  ];
  const videoTypes = ["mp4", "mpge", "mp3", "ogg", "wav", "avi", "mov"];
  const arr = url.split(".");
  const typ = arr[arr.length - 1];
  return imgTypes.includes(typ)
    ? "image"
    : videoTypes.includes(typ)
      ? "video"
      : "none";
};

export const onlineCheck = (t: Date | undefined) => {
  if (t === undefined) return false;
  return Math.abs(new Date().getTime() - t.getTime()) < TIME_FOR_ONLINE_CHECK;
};

export const download = (url: string, name: string) => {
  if (!url) {
    throw new Error("Resource URL not provided! You need to provide one");
  }
  fetch(url)
    .then((response) => response.blob())
    .then((blob) => {
      const blobURL = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = blobURL;
      a.style.display = "display: none";
      a.download = name;
      document.body.appendChild(a);
      a.click();
    })
    .catch(console.log);
};

export function secondsToTime(secondsNum: number) {
  let months = Math.floor(secondsNum / 2629743);
  let days = Math.floor((secondsNum % 2629743) / 86400);
  let hours = Math.floor((secondsNum % 86400) / 3600);
  let minutes = Math.floor((secondsNum - hours * 3600 - days * 86400) / 60);
  let seconds = secondsNum % 60;

  const monthsString = months < 10 ? "0" + months : months.toString();
  const daysString = days < 10 ? "0" + days : days.toString();
  const hoursString = hours < 10 ? "0" + hours : hours.toString();
  const minutesString = minutes < 10 ? "0" + minutes : minutes.toString();
  const secondsString = seconds < 10 ? "0" + seconds : seconds.toString();

  return (
    monthsString +
    ":" +
    daysString +
    ":" +
    hoursString +
    ":" +
    minutesString +
    ":" +
    secondsString
  );
}

export const packEquals = (
  draft: SpecialPack,
  published: SpecialPack,
): boolean => {
  for (let k of Object.keys(draft)) {
    if (k !== "klydos" && draft[k] !== published[k]) return false;
  }
  if (
    published.klydos!.some(
      (kld) => !draft.klydos?.find((klydo) => kld === klydo),
    ) ||
    draft.klydos?.some(
      (kld) => !published.klydos!.find((klydo) => klydo === kld),
    )
  )
    return false;
  return true;
};

export type ParseUrlInput = {
  url: string;
  isStatic?: boolean;
  size?: number;
};

export const parseCloudinaryUrl = (input: ParseUrlInput) => {
  let { url } = input;
  if (!url) return;
  const { isStatic, size } = input;
  const type = url.split(".").pop();
  const params = `c_scale,w_${size}`;
  const framesParams = "so_auto";
  if (isStatic) {
    url = url.replace(/\.(mp4|webp|gif)$/, ".jpg");
    if (url!.includes("image/upload") || url.includes("video/upload")) {
      url = url.replace("/upload/", `/upload/${framesParams}/`);
    } else {
      url = url.replace("/klydoclock/", `/klydoclock/${framesParams}/`);
    }
    return url;
  }
  if (type === "mp4") {
    if (size) {
      if (url!.includes("image/upload") || url.includes("video/upload")) {
        url = url.replace("/upload/", `/upload/${params}/`);
      } else {
        url = url.replace("/klydoclock/", `/klydoclock/${params}/`);
      }
    }
  }
  return url;
};
