import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from "@tanstack/react-query";
import adminApiClient, { User } from "../../../../api/admin";
export type UpdateUserInput = Partial<User> & { id: string; role?: string };

const useUpdateUserMutation = (
  options: UseMutationOptions<User, Error, UpdateUserInput, unknown> = {},
) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ["updateUser"],
    mutationFn: async (input: UpdateUserInput) => {
      return adminApiClient.updateUser(input.id, input);
    },
    onSuccess: (data: User, variables: UpdateUserInput, context: unknown) => {
      queryClient.invalidateQueries({
        queryKey: ["users"],
      });
      options?.onSuccess?.(data, variables, context);
    },
    ...options,
  });
};
export default useUpdateUserMutation;
