import React, { useState } from "react";
import { ListGroup, Modal, Spinner } from "react-bootstrap";
import { BsCheck } from "react-icons/bs";
import KlydoModal from "./Klydos/components/KlydoModal";

import useKlydo from "../components/hooks/useKlydo";
import { isVideo, resizeCloudinary } from "../utils/util";
interface KlydoListItemProps {
  klydoId: string;
  onRemove?: (klydoId: string) => void;
  canRemove?: boolean;
}

const KlydoListItem: React.FC<KlydoListItemProps> = ({
  klydoId,
  onRemove,
  canRemove,
}) => {
  const [showKlydoModal, setShowKlydoModal] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const { data: klydo, isLoading } = useKlydo(klydoId);

  const handleKlydoSelect: React.ComponentProps<
    typeof ListGroup.Item
  >["onClick"] = (event) => {
    event.preventDefault();
    setShowKlydoModal(true);
  };

  const handleRemove = () => {
    setDeleting(true);
    if (onRemove && klydo?.id) onRemove(klydo.id);
    setDeleting(false);
  };

  return (
    <>
      {klydo && !isLoading && (
        <ListGroup.Item
          key={klydo?.id}
          action
          onClick={handleKlydoSelect}
          style={{
            border: 0,
            paddingTop: 0,
            paddingBottom: 0,
          }}
        >
          <div
            style={{
              fontSize: "13px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div style={{ width: 110 }} title={klydo?.id}>
              {klydo?.idf || klydo?.name}
            </div>
            <div style={{ width: 110 }} title={klydo?.creator}>
              {klydo?.author || klydo?.authorName}
            </div>
            {!klydo?.loopUrl || isVideo(klydo?.loopUrl) === "none" ? (
              <div
                style={{
                  width: 35,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p>--</p>
              </div>
            ) : (
              <div
                style={{
                  borderRadius: "50%",
                  width: 35,
                  height: 35,
                  position: "relative",
                  overflow: "hidden",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    position: "absolute",
                    top: klydo?.crop?.top || 0 + "%",
                    left: klydo?.crop?.left || 0 + "%",
                    width: `${klydo?.crop?.zoom || 100}%`,
                    aspectRatio: "1/1",
                  }}
                >
                  {isVideo(klydo?.loopUrl) === "image" ? (
                    <img
                      loading="lazy"
                      alt="gif"
                      src={resizeCloudinary(klydo?.loopUrl, 48)}
                      style={{
                        width: "auto",
                        height: "100%",
                        objectFit: "cover",
                      }}
                    />
                  ) : (
                    <video
                      muted={true}
                      src={resizeCloudinary(klydo?.loopUrl, 48)}
                      loop
                      autoPlay
                      style={{
                        width: "auto",
                        height: "100%",
                        objectFit: "cover",
                      }}
                    />
                  )}
                </div>
              </div>
            )}
            {klydo?.pool ? (
              <BsCheck style={{ width: 25 }} color="black" />
            ) : (
              <div style={{ width: 25 }}></div>
            )}
            {deleting ? (
              <Spinner animation="border" size="sm" />
            ) : (
              canRemove && (
                <i
                  title="Remove klydo"
                  className="nc-icon nc-simple-remove"
                  style={{ width: 6 }}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleRemove();
                  }}
                />
              )
            )}
          </div>
        </ListGroup.Item>
      )}

      {showKlydoModal && (
        <Modal
          className="modal-big modal-primary"
          show={showKlydoModal}
          onHide={() => setShowKlydoModal(false)}
        >
          <KlydoModal row={klydo} setShowModal={setShowKlydoModal} />
        </Modal>
      )}
    </>
  );
};

export default KlydoListItem;
