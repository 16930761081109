import { useEffect, useState } from "react";
import { ListGroup, Modal } from "react-bootstrap";
import { BsCheck } from "react-icons/bs";
import { resizeCloudinary, isVideo } from "../utils/util";
import { CommandHistory, CommandServer, Device, Klydo_deprecated } from "Types";
import firebaseService from "firebase_service/firebaseService";

function EventModal(props: any) {
  const setShowModal = props.setShowModal;
  const event = props.row as CommandServer;
  const params = event.params;
  const [devices, setDevices] = useState<Array<Device>>();
  const [klydos, setKlydos] = useState<Array<Klydo_deprecated>>();

  useEffect(() => {
    firebaseService.getData<Device>("machines").then(setDevices);
    firebaseService.getData<Klydo_deprecated>("klydos").then(setKlydos);
  }, []);

  return (
    <>
      <Modal.Header
        style={{
          padding: 0,
          marginLeft: "10px",
          marginRight: "5px",
          marginBottom: "24px",
        }}
      >
        <h4
          style={{
            fontWeight: "bold",
            margin: 0,
            paddingTop: "16px",
            paddingLeft: "14px",
          }}
        >
          {event.name} Event {params && params[0] === "packs" ? "(Packs)" : ""}
        </h4>
        <i
          style={{ bottom: "7px", position: "relative" }}
          className="nc-icon nc-simple-remove"
          onClick={() => setShowModal(false)}
        ></i>
      </Modal.Header>
      <Modal.Body>
        <div style={{ width: "90%" }}>
          <h4 style={{ margin: 0, paddingTop: "16px", paddingLeft: "14px" }}>
            Recipients:
          </h4>
          <ListGroup
            style={{ borderRadius: 0, border: "none" }}
            className="border-0"
          >
            <ListGroup.Item style={{ border: 0, height: "90%" }}>
              <div
                style={{
                  fontSize: "12px",
                  color: "gray",
                  border: 0,
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div style={{ width: 105 }} className="mb-1">
                  IDF
                </div>
                <div style={{ width: 105 }} className="mb-1">
                  ScheduleTime {event.local ? "(Local)" : "(GMT)"}
                </div>
                <div style={{ width: 105 }} className="mb-1">
                  ReceiveTime
                </div>
                <div style={{ width: 105 }} className="mb-1">
                  ExecutionTime
                </div>
                <div
                  style={{ width: 40, left: "7px", position: "relative" }}
                  className="mb-1"
                >
                  Result
                </div>
              </div>
            </ListGroup.Item>
          </ListGroup>
          <ListGroup
            style={{ border: "none", maxHeight: "190px", overflowY: "auto" }}
            className="border-0"
          >
            {event.cmdHistory.map((hist: CommandHistory) => {
              let device = devices?.find(
                (device) => hist.deviceId === device.id,
              );
              return (
                <ListGroup.Item
                  key={device?.id}
                  style={{
                    border: 0,
                    paddingTop: 0,
                    paddingBottom: 0,
                    borderBottom: "0.01px solid #e8dfdf",
                  }}
                >
                  <div
                    style={{
                      fontSize: "13px",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div style={{ width: 105 }} title={device?.id}>
                      {device?.idf}
                    </div>
                    <div style={{ width: 105 }} title={"ScheduleTime"}>
                      {hist.time.toLocaleString("en-GB")}
                    </div>
                    <div style={{ width: 105 }} title={"ReceiveTime"}>
                      {hist.receiveTime.toLocaleString("en-GB")}
                    </div>
                    <div style={{ width: 105 }} title={"ExecutionTime"}>
                      {hist.executeTime.toLocaleString("en-GB")}
                    </div>
                    <div
                      style={{ width: 40, left: "7px", position: "relative" }}
                      className="mb-1"
                    >
                      {hist.result}
                    </div>
                  </div>
                </ListGroup.Item>
              );
            })}
          </ListGroup>
          {(event.name === "playKlydo" || event.name === "deleteKlydos") &&
            params &&
            params.length > 0 && (
              <>
                <h4
                  style={{ margin: 0, paddingTop: "16px", paddingLeft: "14px" }}
                >
                  Klydos:
                </h4>
                <ListGroup
                  style={{ borderRadius: 0, border: "none" }}
                  className="border-0"
                >
                  <ListGroup.Item style={{ border: 0, height: "90%" }}>
                    <div
                      style={{
                        fontSize: "12px",
                        color: "gray",
                        border: 0,
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ width: 105 }} className="mb-1">
                        IDF
                      </div>
                      <div style={{ width: 105 }} className="mb-1">
                        Author
                      </div>
                      <div
                        style={{ width: 35, left: "7px", position: "relative" }}
                        className="mb-1"
                      >
                        GIF
                      </div>
                      <div
                        style={{
                          width: 12,
                          right: "13px",
                          position: "relative",
                        }}
                        className="mb-1"
                      >
                        Pool
                      </div>
                    </div>
                  </ListGroup.Item>
                </ListGroup>
                <ListGroup
                  style={{
                    border: "none",
                    maxHeight: "190px",
                    overflowY: "auto",
                  }}
                  className="border-0"
                >
                  {params.map((id: string) => {
                    const klydo = klydos?.find((klydo) => klydo.id === id);
                    return (
                      <ListGroup.Item
                        key={klydo?.id}
                        style={{
                          border: 0,
                          paddingTop: 0,
                          paddingBottom: 0,
                          borderBottom: "0.01px solid #e8dfdf",
                        }}
                      >
                        <div
                          style={{
                            fontSize: "13px",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <div style={{ width: 105 }} title={klydo?.id}>
                            {klydo?.idf}
                          </div>
                          <div style={{ width: 105 }} title={klydo?.creator}>
                            {klydo?.author}
                          </div>
                          {!klydo?.loopUrl ||
                          isVideo(klydo?.loopUrl) === "none" ? (
                            <div
                              style={{
                                width: 35,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <p>--</p>
                            </div>
                          ) : (
                            <div
                              style={{
                                borderRadius: "50%",
                                width: 35,
                                height: 35,
                                position: "relative",
                                overflow: "hidden",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  position: "absolute",
                                  top: klydo.crop?.top || 0 + "%",
                                  left: klydo.crop?.left || 0 + "%",
                                  width: `${klydo.crop?.zoom || 100}%`,
                                  aspectRatio: "1/1",
                                }}
                              >
                                {isVideo(klydo.loopUrl) === "image" ? (
                                  <img
                                    loading="lazy"
                                    alt="gif"
                                    src={resizeCloudinary(klydo.loopUrl, 48)}
                                    style={{
                                      width: "auto",
                                      height: "100%",
                                      objectFit: "cover",
                                    }}
                                  />
                                ) : (
                                  <video
                                    muted={true}
                                    src={resizeCloudinary(klydo.loopUrl, 48)}
                                    loop
                                    autoPlay
                                    style={{
                                      width: "auto",
                                      height: "100%",
                                      objectFit: "cover",
                                    }}
                                  />
                                )}
                              </div>
                            </div>
                          )}
                          {klydo?.pool ? (
                            <BsCheck
                              style={{ width: 12 }}
                              color="black"
                            ></BsCheck>
                          ) : (
                            <div style={{ width: 12 }}></div>
                          )}
                        </div>
                      </ListGroup.Item>
                    );
                  })}
                </ListGroup>
              </>
            )}
        </div>
      </Modal.Body>
    </>
  );
}

export default EventModal;
