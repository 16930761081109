import { DraftPack, PackReviewStatus, PublishedPack } from "../Types";
import { baseClient } from "./baseRequest";
import { ApprovedKlydo } from "./klydo";

export type Pack = {
  id: string;
  name: string;
  cover?: string;
  logo?: string;
  klydoIds?: string[];
  klydos?: ApprovedKlydo[];
  description?: string;
  createdAt?: Date;
  updatedAt?: Date;
  creatorId?: string;
  reviewStatus?: {
    state: PackReviewStatus;
    message?: string;
  };
};

export type PackApiClient = {
  createPack: (pack: Partial<DraftPack>) => Promise<void>;
  updatePack: (packId: string, pack: Partial<DraftPack>) => Promise<void>;
  deletePack: (packId: string) => Promise<void>;
  getPackById: (packId: string) => Promise<Pack>;
  getPublishedPacks: (
    query: string,
    skip?: number,
    limit?: number,
    filter?: Record<string, unknown> | undefined,
  ) => Promise<PublishedPack[]>;
  getReviewedPacks: (
    query: string,
    skip: number,
    limit: number,
    filter?: Record<string, unknown> | undefined,
    sort?: { [key: string]: number },
  ) => Promise<DraftPack[]>;
  publishPack: (packId: string) => Promise<void>;
};

const packApiClient: PackApiClient = {
  createPack: async (pack) => {
    return baseClient.post<void>({
      url: "/admin/packs",
      body: pack,
    });
  },
  updatePack: async (packId, pack) => {
    return baseClient.put<void>({
      url: `/admin/packs/${packId}`,
      body: pack,
    });
  },
  deletePack: async (packId) => {
    return baseClient.delete<void>({
      url: `/admin/packs/${packId}`,
    });
  },
  getPackById: async (packId) => {
    return baseClient.get<Pack>({
      url: `/admin/packs/${packId}`,
    });
  },
  getPublishedPacks: async (query, skip, limit, filter) => {
    return baseClient.post<PublishedPack[]>({
      url: `/admin/packs/.search/published-packs?query=${query}`,
      body: {
        skip,
        limit,
        filter,
      },
    });
  },
  getReviewedPacks: (query: string, skip, limit, filters, sort) => {
    return baseClient.post<DraftPack[]>({
      url: `/admin/packs/.search/reviewed-packs?query=${query}`,
      body: {
        limit: limit,
        skip: skip,
        filters: filters,
        sort: sort,
      },
    });
  },
  publishPack: async (packId) => {
    return baseClient.post<void>({
      url: `/admin/packs/publish/${packId}`,
    });
  },
};

export default packApiClient;
