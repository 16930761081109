import { useState, useEffect } from "react";
import { Button, Col, Form, Modal, Row, Spinner } from "react-bootstrap";
import SpecialPackModal from "./SpecialPackModal";
import SpecialPackPoolSwitch from "./SpecialPackPoolSwitch";
import { SpecialPack } from "../../Types";
import firebaseService from "../../firebase_service/firebaseService";
import ButtonLoader from "../../components/Widgets/ButtonLoader";
import MsTable from "../../components/Widgets/Table";

const SpecialPacks = () => {
  const [packs, setPacks] = useState<SpecialPack[]>();
  const [deletePack, setDeletePack] = useState<SpecialPack>();
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>();
  useEffect(() => {
    const lPacks = (packs: SpecialPack[]) => {
      setPacks([...packs]);
    };
    firebaseService.listen<SpecialPack>("packs", lPacks);

    return () => {
      firebaseService.removeListener("packs", lPacks);
    };
  }, []);

  const handlePackUpdate = (pack: SpecialPack) => {
    if (pack) {
      setPacks((packs ?? []).map((p) => (p.id === pack?.id ? pack : p)));
    }
  };

  const DeleteModal = () => {
    const [deleteMsg, setDeleteMsg] = useState("");
    return (
      <Modal
        onHide={() => {
          setShowDeleteModal(false);
          setDeletePack(undefined);
        }}
        className="modal-medium"
        show={true}
      >
        <Modal.Body>
          <Row style={{ margin: 25, marginBottom: 0 }}>
            <h4 style={{ textAlign: "center" }}>
              You are about to delete pack {deletePack!.name}
            </h4>
          </Row>
          <Row className="h-100">
            <Form.Control
              as="textarea"
              rows={4}
              value={deleteMsg}
              style={{ width: "100%", marginBottom: "20px" }}
              onChange={(e) => setDeleteMsg(e.target.value)}
            ></Form.Control>
          </Row>
          <Row className="justify-content-between">
            <Col sm="5">
              <Button
                className="w-100 h-100"
                onClick={() => {
                  setShowDeleteModal(false);
                  setDeletePack(undefined);
                }}
              >
                Cancel
              </Button>
            </Col>
            <Col sm="5">
              <ButtonLoader
                variant="outline-danger"
                className="w-100 h-100"
                title="Delete"
                onClick={async () => {
                  setShowDeleteModal(false);
                  await firebaseService.deletePack(deletePack!).then(() => {
                    setDeletePack(undefined);
                  });
                }}
              />
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    );
  };
  const tableColumns: Array<{
    sort: boolean;
    size?: number;
    label: string;
    field: string;
    noHeadline?: boolean;
  }> = [
    {
      sort: true,
      size: 200,
      label: "Name",
      field: "name",
    },
    {
      sort: true,
      label: "Description",
      field: "description",
    },
    {
      size: 112,
      sort: false,
      label: "Cover",
      field: "imageUrl",
    },
    {
      size: 128,
      sort: false,
      label: "Pool",
      field: "pool",
    },
    {
      size: 32,
      noHeadline: true,
      sort: false,
      label: "",
      field: "delete",
    },
  ];
  const tableRows = (r: SpecialPack, key: string) => {
    return {
      name: r.name,
      description: r.description || "No description",
      imageUrl: r.imageUrl ? (
        <img
          style={{ borderRadius: 50 + "%" }}
          loading="lazy"
          height={90}
          width={90}
          alt="profile"
          src={r.imageUrl}
        ></img>
      ) : (
        "No Image"
      ),
      pool: (
        <SpecialPackPoolSwitch pack={r} handlePackUpdate={handlePackUpdate} />
      ),
      delete:
        deletePack && deletePack.id === r.id && !showDeleteModal ? (
          <Spinner />
        ) : (
          <i
            style={{ margin: 8 + "px" }}
            className="nc-icon nc-simple-remove"
            onClick={(e) => {
              e.stopPropagation();
              setDeletePack(r);
              setShowDeleteModal(true);
            }}
          />
        ),
      key: key,
    };
  };
  return (
    <>
      {packs ? (
        <>
          <MsTable
            id="packs"
            cols={tableColumns}
            modal={SpecialPackModal}
            initSort="name"
            rowBuild={tableRows}
            title={"Packs"}
            data={packs}
            handleChangedPack={handlePackUpdate}
            search={(row: SpecialPack, val: string) => {
              return (
                row.name.toLowerCase().includes(val) ||
                row.id.toLowerCase().includes(val) ||
                row.description?.toLowerCase().includes(val) ||
                false
              );
            }}
          />
        </>
      ) : (
        <Spinner></Spinner>
      )}
      {showDeleteModal && <DeleteModal />}
    </>
  );
};

export default SpecialPacks;
