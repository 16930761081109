type KlydoLoopUrlProps = {
  loopUrl: string;
};

const convertToMp4 = (url: string) => {
  if (url.endsWith(".gif")) {
    return url.replace(/\.gif$/, ".mp4");
  }
  return url;
};
const KlydoLoopUrl = ({ loopUrl }: KlydoLoopUrlProps) => {
  if (loopUrl) {
    const convertedLoopUrl = convertToMp4(loopUrl);
    return (
      <video
        muted={true}
        src={convertedLoopUrl}
        style={{
          width: "100%",
          height: "100%",
          borderRadius: "50%",
          objectFit: "cover",
        }}
        autoPlay
        loop
        playsInline
      />
    );
  } else {
    return (
      <div
        style={{
          width: "100%",
          aspectRatio: "1",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <p>This file type is not supported</p>
      </div>
    );
  }
};
export default KlydoLoopUrl;
