import { useMutation, useQueryClient } from "@tanstack/react-query";
import adminApiClient, { KlydoAnalyzerConfig } from "../../../api/admin";

const useAnalyzerConfigMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ["analyzerConfig"],
    mutationFn: (data: KlydoAnalyzerConfig) =>
      adminApiClient.updateAnalyzerConfig(data),
    onSettled: () =>
      queryClient.refetchQueries({ queryKey: ["analyzerConfig"] }),
  });
};

export default useAnalyzerConfigMutation;
