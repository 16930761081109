import { Device, SpecialPack } from "Types";
import firebaseService from "firebase_service/firebaseService";
import { useState, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import ButtonLoader from "./ButtonLoader";

function PackList(props: {
  device: Device;
  onSave: (list: string[]) => Promise<void>;
}) {
  const [show, setShow] = useState(false);
  const [allPacks, setAllPacks] = useState<Array<SpecialPack>>();
  const [toAddPacks, setToAddPacks] = useState<Array<string>>(
    props.device.premium ? [...props.device.premium] : [],
  );
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    if (!show) return;
    const l = (packs: Array<SpecialPack>) => {
      setAllPacks(packs.filter((pack) => !pack.pub));
    };
    firebaseService.listen("packs", l);
    return () => firebaseService.removeListener("packs", l);
  }, [show]);

  const packStyle = (s: string, i: number) => (
    <div
      key={i}
      style={{
        cursor: "pointer",
        display: "flex",
        margin: 5,
        padding: "1px 6px",
        alignItems: "center",
        background: "gray",
        borderRadius: "50vw",
        height: "fit-content",
      }}
    >
      <p
        style={{
          margin: 0,
          padding: "2px",
          fontFamily: "monospace",
          fontSize: "11px",
          color: "white",
        }}
      >
        {s}
      </p>
    </div>
  );

  return (
    <>
      <div>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            flex: "1 1 auto",
            cursor: "pointer",
          }}
          onClick={(e) => {
            e.stopPropagation();
            setShow(!show);
          }}
        >
          {props.device?.premium?.map(packStyle)}
          {props.device &&
            (props.device.premium === undefined ||
              props.device.premium.length === 0) && (
              <span
                className="nc-icon nc-simple-add"
                style={{
                  cursor: "pointer",
                  padding: 5,
                  fontSize: 10,
                  fontWeight: 700,
                  background: "gray",
                  color: "white",
                  alignSelf: "center",
                  borderRadius: "50%",
                }}
              ></span>
            )}
          {props.device.premium?.length! > 0 && navigator?.clipboard && (
            <i
              title="Copy packs"
              className={`nc-icon ${copied ? "nc-check-2" : "nc-single-copy-04"}`}
              style={{
                position: "absolute",
                right: "1px",
                top: "25px",
                cursor: "pointer",
              }}
              onClick={(e) => {
                e.stopPropagation();
                navigator.clipboard
                  .writeText(props.device.premium!.join(","))
                  .then(() => {
                    setCopied(true);
                    setTimeout(() => {
                      setCopied(false);
                    }, 2000);
                  })
                  .catch(console.log);
              }}
            ></i>
          )}
        </div>
        {show && (
          <Modal show={true} onHide={() => setShow(false)}>
            <Modal.Header
              style={{
                padding: 0,
                marginLeft: "10px",
                marginRight: "5px",
                marginBottom: "24px",
              }}
            >
              <h4
                style={{ margin: 0, paddingTop: "16px", paddingLeft: "14px" }}
              >
                Add/Remove Packs
              </h4>
              <i
                style={{ bottom: "7px", position: "relative" }}
                className="nc-icon nc-simple-remove"
                onClick={() => {
                  setShow(false);
                }}
              ></i>
            </Modal.Header>
            {allPacks && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  maxHeight: "140px",
                  overflowY: "auto",
                }}
              >
                {allPacks.map((pack) => (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginTop: 5,
                      borderBottom: "1px solid #eee",
                      marginLeft: "5px",
                    }}
                  >
                    <input
                      defaultChecked={
                        !!props.device.premium?.find(
                          (myPack) => pack.tag === myPack,
                        )
                      }
                      onChange={(event) => {
                        if (event.target.checked) toAddPacks?.push(pack.tag);
                        else
                          toAddPacks?.splice(toAddPacks.indexOf(pack.tag), 1);
                        setToAddPacks((prev) => prev?.slice());
                      }}
                      type="checkbox"
                    />
                    <p style={{ width: "10%", margin: 0 }}>{pack.name}</p>
                    <p style={{ width: "10%", margin: 0 }}>{pack.tag}</p>
                    <img
                      style={{ borderRadius: 50 + "%" }}
                      loading="lazy"
                      height={48}
                      width={48}
                      alt="profile"
                      src={pack.imageUrl}
                    ></img>
                  </div>
                ))}
              </div>
            )}
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                width: "100%",
              }}
            >
              <Button
                style={{
                  color: "#FF1020",
                  borderColor: "#FF1020",
                  margin: 5,
                  justifySelf: "right",
                  height: "fit-content",
                  marginRight: "5px",
                  padding: "0px 4px",
                  marginTop: "5px",
                }}
                onClick={() => {
                  setShow(false);
                }}
              >
                Close
              </Button>
              <ButtonLoader
                style={{
                  margin: 5,
                  justifySelf: "right",
                  height: "fit-content",
                  marginRight: "5px",
                  padding: "0px 4px",
                  marginTop: "5px",
                }}
                title="Save"
                onClick={async () => {
                  return props.onSave(toAddPacks).then(() => setShow(false));
                }}
              >
                Save
              </ButtonLoader>
            </div>
          </Modal>
        )}
      </div>
    </>
  );
}

export default PackList;
