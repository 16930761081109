import { useMutation, useQueryClient } from "@tanstack/react-query";
import giftCategoriesClient from "../../../../api/giftCategories";

const useDeleteOrderGiftMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ["gift"],
    mutationFn: (orderId: string) =>
      giftCategoriesClient.deleteOrderGift(orderId),
    onSuccess: () =>
      queryClient.refetchQueries({
        queryKey: ["giftOrders"],
      }),
  });
};
export default useDeleteOrderGiftMutation;
