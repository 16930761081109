import { useInfiniteQuery, useQueryClient } from "@tanstack/react-query";
import packApiClient from "../../../api/pack";
import { DraftPack } from "../../../Types";

const usePacksReviewInfiniteQuery = (textSearch: string) => {
  const queryClient = useQueryClient();
  const limit = 20;

  const fetchReviewedPacks = async (
    queryClient: ReturnType<typeof useQueryClient>,
    query: string,

    pageParam?: {
      skip?: number;
      limit?: number;
      filters?: Record<string, unknown>;
      sort?: { [key: string]: number };
    },
  ): Promise<DraftPack[]> => {
    const { skip = 0, limit = 20, filters = {}, sort = {} } = pageParam || {};
    const res = await packApiClient.getReviewedPacks(
      query,
      skip,
      limit,
      filters,
      sort,
    );

    res.forEach((pack) => {
      queryClient.setQueryData(["pack", pack.id], pack);
    });
    return res;
  };

  const { data, fetchNextPage, ...rest } = useInfiniteQuery<DraftPack[], Error>(
    {
      queryKey: ["packs", textSearch],
      queryFn: ({ pageParam }) =>
        fetchReviewedPacks(
          queryClient,
          textSearch,
          pageParam as {
            skip?: number;
            limit?: number;
            filters?: Record<string, unknown>;
            sort?: { [key: string]: number };
          },
        ),
      getNextPageParam: (lastPage, allPages) => {
        const currentLength = allPages.flat()?.length;
        return currentLength < limit
          ? undefined
          : { skip: currentLength, limit };
      },
      initialPageParam: { skip: 0, limit },
    },
  );

  return {
    data: data?.pages.flat(),
    fetchNextPage,
    ...rest,
  };
};

export default usePacksReviewInfiniteQuery;
