import React, { useState } from "react";
import { Form, Modal, Button } from "react-bootstrap";
import { SpecialPack } from "../../Types";
import packService from "../../firebase_service/packService";

interface SpecialPackPoolSwitchProps {
  pack: SpecialPack;
  handlePackUpdate: (pack: SpecialPack) => void;
}

const SpecialPackPoolSwitch: React.FC<SpecialPackPoolSwitchProps> = ({
  pack,
  handlePackUpdate,
}) => {
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [newPoolStatus, setNewPoolStatus] = useState(false);

  const handleSwitchClick = () => {
    setNewPoolStatus(!pack.pub);
    setShowConfirmModal(true);
  };

  const handleConfirm = () => {
    packService
      .updateSpecialPack(pack.id, { pub: newPoolStatus })
      .then(() => {
        const updatedPack = { ...pack, pub: newPoolStatus };
        handlePackUpdate(updatedPack);
        setShowConfirmModal(false);
      })
      .catch((error) => {
        console.error("Error updating pack pool status:", error);
      });
  };

  return (
    <>
      <Form.Switch
        style={{
          display: "flex",
          justifyContent: "center",
        }}
        checked={pack.pub || false}
        type="switch"
        onChange={handleSwitchClick}
        onClick={(e) => e.stopPropagation()}
      />

      <Modal show={showConfirmModal} onHide={() => setShowConfirmModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Pool Status Change</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to{" "}
          {newPoolStatus ? "add this pack to" : "remove this pack from"} the
          pool?
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={(e) => {
              e.stopPropagation();
              setShowConfirmModal(false);
            }}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={(e) => {
              e.stopPropagation();
              handleConfirm();
            }}
          >
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default SpecialPackPoolSwitch;
