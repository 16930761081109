import { useQuery } from "@tanstack/react-query";
import adminApiClient from "../../../api/admin";

const useAnalyzerConfigQuery = () => {
  return useQuery({
    queryKey: ["analyzerConfig"],
    queryFn: () => adminApiClient.getAnalyzerConfig(),
  });
};

export default useAnalyzerConfigQuery;
