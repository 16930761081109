import { useState } from "react";
import ConfirmationModal from "./ConfirmationModal";
import { Tooltip } from "@mui/material";
import DSSwitch from "./system-design/DSSwitch";

export type ConfirmationSwitchProps = {
  status: boolean;
  action: (status: boolean) => void;
  onMessage: string;
  offMessage: string;
  disabledReason?: string;
};

const ConfirmationSwitch = ({
  status,
  action,
  onMessage,
  offMessage,
  disabledReason,
}: ConfirmationSwitchProps) => {
  const [showConfirmationModal, setShowConfirmationModal] =
    useState<boolean>(false);
  const [confirmationMessage, setConfirmationMessage] = useState<string>("");
  const [tempChecked, setTempChecked] = useState<boolean>(!!status);

  const handleSwitchChange = (checked: boolean) => {
    setTempChecked(checked);
    setConfirmationMessage(checked ? onMessage : offMessage);
    setShowConfirmationModal(true);
  };

  const handleConfirm = () => {
    setShowConfirmationModal(false);
    action(tempChecked);
  };

  const handleCancel = () => {
    setShowConfirmationModal(false);
  };

  return (
    <div style={{ position: "relative" }} onClick={(e) => e.stopPropagation()}>
      <Tooltip title={disabledReason || ""} placement="top" arrow>
        <span>
          <DSSwitch
            checked={status || false}
            onChange={(e) => handleSwitchChange(e.target.checked)}
            disabled={!!disabledReason}
          />
        </span>
      </Tooltip>

      <ConfirmationModal
        open={showConfirmationModal}
        message={confirmationMessage}
        onClose={handleCancel}
        onConfirm={handleConfirm}
      />
    </div>
  );
};

export default ConfirmationSwitch;
