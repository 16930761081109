import { useInfiniteQuery, useQueryClient } from "@tanstack/react-query";
import { PublishedPack } from "../../../Types";
import { ApprovedKlydo } from "../../../api/klydo";
import packApiClient from "../../../api/pack";

const usePublishedPacksInfiniteQuery = (textSearch: string) => {
  const queryClient = useQueryClient();
  const limit = 10;

  const fetchPacks = async (
    pageParam: {
      skip?: number;
      limit?: number;
      filter?: Record<string, unknown>;
    },
    queryClient: ReturnType<typeof useQueryClient>,
    query: string,
  ): Promise<PublishedPack[]> => {
    const { skip = 0, limit, filter } = pageParam;
    const res = await packApiClient.getPublishedPacks(
      query,
      skip,
      limit,
      filter ?? {},
    );

    res.forEach((pack) => {
      queryClient.setQueryData(["pack", pack.id], pack);
    });

    const klydos = res
      .map((k) => k.klydos)
      .flat()
      .filter(Boolean) as ApprovedKlydo[];

    for (const klydo of klydos) {
      queryClient.setQueryData(["klydo", klydo.id], klydo);
    }

    return res;
  };

  const { data, fetchNextPage, ...rest } = useInfiniteQuery<
    PublishedPack[],
    Error
  >({
    queryKey: ["packs", textSearch],
    queryFn: ({ pageParam }) =>
      fetchPacks(
        { ...(pageParam ?? { skip: 0, limit }) },
        queryClient,
        textSearch,
      ),
    getNextPageParam: (lastPage, allPages) => {
      const currentLength = allPages.flat()?.length;
      return currentLength < limit ? undefined : { skip: currentLength, limit };
    },
    initialPageParam: { skip: 0, limit },
  });
  return {
    data: data?.pages.flat(),
    fetchNextPage,
    ...rest,
  };
};
export default usePublishedPacksInfiniteQuery;
