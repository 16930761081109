import { useInfiniteQuery } from "@tanstack/react-query";
import adminApiClient from "../../../api/admin";
const PAGE_SIZE = 100;

const useClocksInfiniteQuery = (query: string, devClocks?: boolean) => {
  const { data: machinesNewData, ...rest } = useInfiniteQuery({
    queryKey: ["machines", query, devClocks],
    queryFn: async ({ pageParam }) => {
      const { skip = 0 } = pageParam as { skip?: number };
      const res = await adminApiClient.getClocks(
        skip,
        PAGE_SIZE,
        query,
        devClocks,
      );

      return res;
    },
    getNextPageParam: (lastPage, allPages) => {
      const currentLength = allPages.flat().length;
      return currentLength < PAGE_SIZE ? undefined : { skip: currentLength };
    },
    initialPageParam: { skip: 0 },
  });
  return {
    data: machinesNewData?.pages.flat(),
    ...rest,
  };
};

export default useClocksInfiniteQuery;
