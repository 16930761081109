import { SpecialPack } from "../Types";
import firebaseService from "./firebaseService";

class PackService {
  private firebaseService = firebaseService;

  public async updateSpecialPack(
    packId: string,
    pack: Partial<SpecialPack>,
  ): Promise<void> {
    await this.firebaseService.db.collection("packs").doc(packId).update(pack);
  }

  public async upsertSpecialPack(
    pack: Omit<SpecialPack, "id"> & { id?: string },
  ): Promise<string> {
    if (pack.id) {
      await this.firebaseService.db
        .collection("packs")
        .doc(pack.id)
        .update(pack);
      return pack.id;
    } else {
      const result = await this.firebaseService.db
        .collection("packs")
        .add(pack);
      return result.id;
    }
  }
}
const packService = new PackService();
export default packService;
